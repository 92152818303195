// shared.module.ts

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalLeadersComponent } from '../Components/global-leaders/global-leaders.component';
import { TestimonialComponent } from '../Components/testimonial/testimonial.component';
import { GrammarTipsModalComponent } from '../Components/videos/grammar-tips/grammar-tips-modal/grammar-tips-modal.component';
import { ProductWalkthroughtModalComponent } from '../Components/product-walkthrought-modal/product-walkthrought-modal.component';
import { GetTrinkaComponent } from '../Components/enterprise/get-trinka/get-trinka.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    GlobalLeadersComponent,
    TestimonialComponent,
    GrammarTipsModalComponent,
    ProductWalkthroughtModalComponent,
    GetTrinkaComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    GlobalLeadersComponent,
    TestimonialComponent,
    GrammarTipsModalComponent,
    ProductWalkthroughtModalComponent,
    GetTrinkaComponent
  ]
})

export class SharedModule { }
