<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Premium Feature</h1>
                                <h2>Trinka for Microsoft Word</h2>
                                <p>Trinka Add-In for Word makes effective writing easier than ever! Trinka auto-checks your writing
                                    and provides corrections as you write within your familiar Word app.</p>
                                    <div class="wordPluginCTA" id="MSWindowDownloadButton">
                                        <b id="windowInfo64bit"></b>
                                        <!-- <i class="">Beta</i> -->
                                    </div>
                                    <span id="MSWindowTextContent">*The Add-In is Compatible with Office 2010 & above & works on Windows only.</span>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/word-add-in-banner.svg" alt="ms word">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src="../../../assets/images/microsoft-wordThumbnails.jpg" loading="lazy" decoding="async" alt="word addin" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Need help in installing or want to know how to make corrections using the add-in?
                            </p>
                            <p>Check out the easy walk-through video to get started in no time.
                            </p>
                            <p class="mb-0">Have more questions? Browse through our FAQs <a (click)="scroll(target)" class="text-link">here</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Microsoft Word Add-In</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka for MS Word is available as an add-in to the familiar MS Word application that you have
                            always used. Get professional grammar corrections and suggestions as you write, with Trinka automatically
                            checking all your changes so you don’t miss anything!</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/grammar-checker-icon.svg" alt="Grammar Checker" />
                        <h3>English Grammar Checker</h3>
                        <p>Get real-time writing suggestions and advanced grammar checks for your content within your MS Word environment.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/multilingual-icon.png" alt="Grammar Checker" />
                        <h3>Multilingual Grammar Check</h3>
                        <p>Easily perfect your writing in Spanish with our advanced Grammar Checker. Trinka automatically detects the language and suggest improvements.

                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/error-highlight-icon.svg" alt="Interactive Error Highlighting">
                        <h3>Interactive Error<br class="d-lg-block d-none"/> Highlighting</h3>
                        <p>Errors within your document are effortlessly highlighted directly on the page with suggestions displayed in a dedicated right panel.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/style_guide_icon.svg" alt="Style Guide Preferences">
                        <h3>Style Guide Preferences</h3>
                        <p>Tailor the grammar & word choice of your manuscript to comply with popular academic style guides. (APA, AMA, AGU, ACS, IEEE).</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 dflex">
                    <div class="commingSoonTag list-wrapper-content-2 list-style-cards bt-1">
                        <span class="badge">Unlimited Standard Check<br/> with Premium Plus </span>
                        <img src="../../../assets/images/ppc_icon.png" alt="ppc">
                        <h3>Plagiarism Checks</h3>
                        <p>Get robust <a [routerLink]="['/features/plagiarism-check']" class="text-link">Plagiarism checks</a> with a database of 82+ million paid publications and internet content. You can utilize your Trinka Credits to download detailed reports.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        
                        <img src="../../../assets/images/paraphraser-icon.svg" alt="Paraphraser">
                        <h3>Paraphraser<br class="d-lg-block d-none"/>&nbsp; <div class="beta-badges">New</div></h3>
                        <p><a [routerLink]="['/paraphrasing-tool']" class="text-link">Paraphrase</a> as you write with AI that understands your intent and offers suggestions to make your writing clear, coherent, and grammatically correct.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/autoFileEdit_icon.svg" alt="auto file edit">
                        <h3>Auto Proofread File</h3>
                        <p>Get quality scores and save time with automatic editing in Microsoft Word. You can quickly finalize your edited file with track changes!</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/subjectAreaAndDocumentType.svg" alt="subject">
                        <h3>Subject Area & Document Type</h3>
                        <p>Customize Trinka to provide you the most relevant suggestions based on your subject area and type
                            of manuscript.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 dflex">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/AddtoDictionary.svg" alt="Mac Word | Add to Dictionary" />
                        <h3>Add to Dictionary</h3>
                        <p>Add your own words to "My Dictionary" and Trinka will not flag them as spelling errors in your future writing.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            How do I add/install Trinka for Microsoft Word?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>On the Trinka for MS Word Add-In page you can find the "<span id="windowInfo64bit1">Download Now</span>" button. Once you download the installation file, click on it to start the installation
                                                process. After successful installation open the MS Word application. On the top
                                                ribbon you will find a new tab named Trinka. Click on the tab and click "Login"
                                                to login with your premium account and start using Trinka.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">How do I turn on Trinka Grammar check in Word?</a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Once you download and install the Trinka Add-In for MS Word, you will just need to
                                                log in using your Trinka account credentials and click on the "Start" button
                                                on the Trinka tab on the ribbon menu. </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            What are the system requirements for installing Trinka on Microsoft Word?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>The Word plug-in works with Microsoft Word 2010 and later versions for Windows.
                                            </p>
                                            <p>8GB or more of free space is required to install the Windows version of the Word plug-in.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            Can I use Trinka Word Add-In on Mac?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Refer to this link for the Add-In for Mac MS Word - <a href="https://appsource.microsoft.com/en-us/product/office/WA200004694?tab=Overview" target="_blank">https://appsource.microsoft.com/en-us/product/office/WA200004694?tab=Overview</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionFive">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFiveContent" aria-expanded="true"
                                            aria-controls="QuestionFiveContent">
                                            Does Trinka's MS Word Add-In check for spelling and grammar in real time?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFiveContent" class="collapse" aria-labelledby="QuestionFive" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes, Trinka's MS Word Add-In checks and suggests corrections in real-time. Moreover,
                                                it detects any changes that you make and rechecks your writing for corrections
                                                instantly.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionSix">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionSixContent" aria-expanded="true"
                                            aria-controls="QuestionSixContent">
                                            How do I accept or reject a suggestion?
                                        </a>
                                    </div>
    
                                    <div id="QuestionSixContent" class="collapse" aria-labelledby="QuestionSix" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Accepting or rejecting a suggestion is very easy. Just click on the suggested word
                                                to accept/apply the correction, or click on the ‘Thumbs down’ icon to reject it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionSeven">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionSevenContent" aria-expanded="true"
                                            aria-controls="QuestionSevenContent">
                                            Can I switch between US and UK English on Trinka's Word Add-In?
                                        </a>
                                    </div>
    
                                    <div id="QuestionSevenContent" class="collapse" aria-labelledby="QuestionSeven" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes. You can switch between US English and UK English from the Language Check and
                                                Settings menu option on the ribbon menu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionEightSection">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionEightSectionContent" aria-expanded="true"
                                            aria-controls="QuestionEightSectionContent">
                                            Can I select between style guides in Trinka Word Add-In?
                                        </a>
                                    </div>
    
                                    <div id="QuestionEightSectionContent" class="collapse" aria-labelledby="QuestionEightSection" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes. You can select the preferred style guide from the Language Check and Settings
                                                menu option on the ribbon menu.</p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionEight">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionEightContent" aria-expanded="true"
                                            aria-controls="QuestionEightContent">
                                            How do I use Trinka if I don't have MS Word and I write online (Medium, etc.)?
                                        </a>
                                    </div>
    
                                    <div id="QuestionEightContent" class="collapse" aria-labelledby="QuestionEight" data-parent="#faq">
                                        <div class="card-body">
                                            <p>You can use our Browser Plug-Ins for Chrome, Firefox and Edge to use Trinka on your favorite websites. Click here do download <a href="/browser-plugin" class="text-left">Trinka Browser Plug-Ins</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionTen">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTenContent" aria-expanded="true"
                                            aria-controls="QuestionTenContent">
                                            What should I do if I cannot find Trinka's Word Add-In after successful installation?
                                        </a>
                                    </div>
    
                                    <div id="QuestionTenContent" class="collapse" aria-labelledby="QuestionTen" data-parent="#faq">
                                        <div class="card-body">
                                            <p>In some cases, your system/organization's security can disable the activation of the
                                                add-in. You can try manually enabling it from File -> Options -> Add-Ins -> Manage
                                                -> COM -Add-Ins.
                                                <a href="https://youtu.be/RDrzQOBNAsk" target="_blank">Watch this video</a> for a detailed walkthrough.</p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="Question11">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#Question11Content" aria-expanded="true"
                                            aria-controls="Question11Content">
                                            What should I do if the Trinka Word Add-In is unable to detect errors?
                                        </a>
                                    </div>
    
                                    <div id="Question11Content" class="collapse" aria-labelledby="Question11" data-parent="#faq">
                                        <div class="card-body">
                                            <p>This could be due to multiple issues. </p>
                                            <ul>
                                                <li>Please check your internet connection. You need to be connected to the internet for Trinka to detect errors and suggest corrections.</li>
                                                <li>Check if the Add-In is not disabled because of your system/organization's security settings.
                                                </li>
                                                <li>Try completely uninstalling from Windows control panel and re-installing the add-in.</li>
                                                <li>Please check the display settings of your PC, go to Windows settings -> System -> Click “Display” on the left navigation pane -> Click “Scale and layout” -> Please make sure the scaling is set to 100% or 125%.</li>
                                                <li>If you are using multiple displays, move your MS Word Window to the primary monitor.</li>
                                                <li>If the issue still persists, please email us at <a href="mailto:support@trinka.ai">support@trinka.ai</a> and our team will help you resolve it.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="icon">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="icon">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/cTo16u94rxc"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>