<div class="trinka_cloud_wrap">

    <div class="trinka_cloud_wrap1">
        <!-- <div class="cloud_top_banners">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="trinka_cloud_banner_text">
                            <h1>Citation Checker</h1>
                            <h2>Strengthen Your <br/>Research Argumentation</h2>
                            <p>Trinka Citation Checker performs automated citation analysis to identify citations that can make your research premise and argumentation weaker and helps you cite credible, strong citations for your paper. </p>
                            <a href="https://cloud.trinka.ai/signup?_rg=GB" id="autoEditGetStart" target="_blank">Check Now</a>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="trinka_cloud_banner_img text-center" style="text-align: center !important;">
                            <img src="../../../assets/images/Reference-Quality-Check.png" alt="Citation Checker">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Citation Checker</h1>
                                <h2>Strengthen Your Research Argumentation</h2>
                                <p>Trinka Citation Checker automatically identifies citations that weaken your research premise and arguments and helps you cite credible, strong citations.</p>
                                <div class="btn-group">
                                    <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                                </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/Reference-Quality-Check.png" alt="Citation Checker" class="img-fluid md50">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <div class="howItWorks section-pd list-wrapper-style-2" #viewVideo>
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/citation-video.png" loading="lazy" decoding="async" alt="citation video" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>See how Trinka Citation Checker works and get started in no time.</p>
                            <p>The easy walk-through video will help you get the most out of this feature.</p>
                            <p>Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Citation Checker </h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka Citation Checker identifies various quality risks for a robust citation analysis that
                            lets you improve your citation list and strengthen your research argumentation.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/Retracted-References.png" loading="lazy" decoding="async" alt="Retracted">
                        <h3>Retracted Citations</h3>
                        <p>Identify studies that have been recalled after publication and avoid peer review critique.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/Non-standard-References.png" loading="lazy" decoding="async" alt="non standard">
                        <h3>Non-Standard Citations</h3>
                        <p>Improve your citation credibility by eliminating non-peer-reviewed works.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/low-visibility.png" loading="lazy" decoding="async" alt="low visibility">
                        <h3>Low Visibility Citations</h3>
                        <p>Identify citations that have not been frequently cited by other authors to avoid weakening your argumentation strength.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/Old-References.png" loading="lazy" decoding="async" alt="old reference">
                        <h3>Old References</h3>
                        <p>Easily identify papers published long ago to ensure high relevance and validity of your citations.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/Overuse.png" loading="lazy" decoding="async" alt="overuse">
                        <h3>Journal Overuse</h3>
                        <p>Identify unintentional bias toward a journal and avoid peer review critique by diversifying
                            your citation sources.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="cloud_key_features section-pd list-wrapper-style-2" style="background-color: #f3eef6;">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Why Choose Trinka Citation Checker</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <div class="media gap-15">
                            <div class="media-left">
                                <img src="../../../../assets/images/languageScore_icon.svg" loading="lazy" decoding="async" alt="Free Score Overview" />
                            </div>
                            <div class="media-body">
                                <h3>Free Score Overview</h3>
                                <p>Generate citation analysis score for free and only pay for the report when needed.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <div class="media gap-15">
                            <div class="media-left">
                                <img src="../../../../assets/images/Up-to-date-Repository.png" loading="lazy" decoding="async" alt="Free Score Overview" />
                            </div>
                            <div class="media-body">
                                <h3>Crossref Validation</h3>
                                <p>All your citations are validated against Crossref’s extensive publication repository trusted by publishers and organizations worldwide.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-security-section></app-security-section>

    <div class="cloud_key_features section-pd pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>See a Sample Report!</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>See a sample citation analysis report by Trinka Citation Checker to understand how you
                            can benefit from it for your paper!</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="viewSampleReportImg">
                        <a href="citation-checker/sample-report/">View Full Report <i class="fa fa-arrows-alt" aria-hidden="true"></i></a>
                        <img src="../../../assets/images/citation-report.jpg" loading="lazy" decoding="async" alt="citation">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Get Your Free Citation Analysis Score Now</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Get Started</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div class="trinka-credits section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Citation Checker Uses Credits</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-10">
                    <div class="credit-section">
                        <div class="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" width="745" height="140" viewBox="0 0 745 140" fill="none">
                                <path d="M210.74 99.3391C92.7255 118.025 21.074 68.6063 0 41.5614V169H721.602C749.822 122.013 775.522 23.2037 652.567 3.86251C498.872 -20.314 358.258 75.9822 210.74 99.3391Z" fill="#F5ECFF"/>
                            </svg>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../assets/images/new-credits.svg" loading="lazy" decoding="async" alt="credits" />
                            </div>
                            <div class="media-body">
                                <h6>Citation Analysis scores are free; you only need to pay for the full report. To generate
                                    the report, 1 credit is used for up to 30 citations. Use free credits or buy more as
                                    needed.</h6>
                            </div>
                        </div>
                        <div class="image">
                            <img src="../../../assets/images/credits-banner.svg" loading="lazy" decoding="async" alt="credits" />
                        </div>
                    </div>
                    <ul>
                        <li><span>*</span>
                            <p>
                                Credits are like tokens that let you use several Trinka features as needed.<br/>
                                Every month, Premium users get 10 free credits and Basic users get 4 free credits. To know more, <a [routerLink]="['/features/credits/']" class="text-link">click here</a>.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            How does Trinka Citation Checker work?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <h4>Improve the quality of your citations in 3 simple steps! </h4>
                                            <ol>
                                                <li>Just <a href="https://cloud.trinka.ai/signup" target="_blank">Sign Up</a> for free on Trinka. </li>
                                                <li>Go to the Citation Checker page from the left navigation panel. </li>
                                                <li>Upload your document. You can upload from Google Drive, Dropbox, or your computer. You can also use document already uploaded on Trinka. You will get your report in a few minutes!</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            Does Trinka review citation to paywalled articles?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes. We can quality check your citations even if they are published in paywalled journals.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">
                                            Where can I access Trinka Citation Checker? </a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Trinka Citation Checker feature is currently available only on the Trinka Cloud
                                                platform to all users. It will come soon to the Word add-in too. </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            Can I share the citation report with my co-authors?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <!-- <p>In addition to all the features you get in Basic, you also get Power Mode with a Premium subscription.  Power Mode provides users with up to 40% more suggestions for their content while considering the context of the writing. Furthermore, </p> -->
                                            <p>Currently, the report is not shareable. We will make that happen in the future! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="pricingQuestionAnwser" #target>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="pricingHeadingQuestion">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="pricingQuestionsList">
                        <div class="accordion" id="faq">
                            <div class="card">
                                <div class="card-header" id="QuestionOne">
                                    <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                        How does Trinka Citation Checker work?</a>
                                </div>

                                <div id="QuestionOneContent" class="collapse show" aria-labelledby="QuestionOne" data-parent="#faq">
                                    <div class="card-body">
                                        <h4>Improve the quality of your citations in 3 simple steps! </h4>
                                        <ol>
                                            <li>Just <a href="https://cloud.trinka.ai/signup" target="_blank">Sign Up</a> for free on Trinka. </li>
                                            <li>Go to the Citation Checker page from the left navigation panel. </li>
                                            <li>Upload your document. You can upload from Google Drive, Dropbox, or your computer. You can also use document already uploaded on Trinka. You will get your report in a few minutes!</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="QuestionTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                        aria-controls="QuestionTwoContent">
                                        Where can I access Trinka Citation Checker? </a>
                                </div>

                                <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Citation Checker feature is currently available only on the Trinka Cloud
                                            platform to all users. It will come soon to the Word add-in too. </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                        aria-controls="QuestionThreeContent">
                                        Does Trinka review citation to paywalled articles?
                                    </a>
                                </div>

                                <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes. We can quality check your citations even if they are published in paywalled journals.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        Can I share the citation report with my co-authors?
                                    </a>
                                </div>

                                <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Currently, the report is not shareable. We will make that happen in the future! </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-1"></div>
            </div>
        </div>
    </div> -->


    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="icon">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" alt="icon">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="icon">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/XBuFdCM3xtk"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>