<section class="section-pd list-wrapper-style-2 pt-0 pb-40">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="trinka-title text-center mb-0">
                    <h2>Customizable Views: <br class="d-md-block d-none"/>Tailor Your Suggestion Alerts</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>Individual Alert View </h3>
                    <p>Review word-level suggestions in a dedicated alert card with options to accept or dismiss. </p>
                    <ul class="bt-list">
                        <li>Review each suggestion in a separate card.</li>
                        <li>Focus only on one alert at a time, avoiding information overload.</li>
                        <li>Gain a better control and flexibility for every suggestion.</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-center">
                    <video class="vid-video w-100" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                        <source src="/assets/images/features/grammar-checker/individual-alerts.mp4" type="video/mp4">
                        Your browser does not support the video element. Kindly update it to latest version.
                    </video>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>Sentence Alert View </h3>
                    <p>View all the suggestions for a sentence in a single card and accept or dismiss them in one-click. </p>
                    <ul class="bt-list">
                        <li>Save time by correcting full sentences in one go. </li>
                        <li>Fewer cards to review reduces clutter. </li>
                        <li>Accept or dismiss word-level suggestions within sentences <span class="beta-badges" style="margin-left:5px">Coming Soon</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-center">
                    <video class="vid-video w-100" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                        <source src="/assets/images/features/grammar-checker/sentence-alerts.mp4" type="video/mp4">
                        Your browser does not support the video element. Kindly update it to latest version.
                    </video>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pb-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>Bubble Alert View </h3>
                    <p>Expand your writing space and get suggestions and alerts right where you write.  </p>
                    <ul class="bt-list">
                        <li>Enjoy a more immersive and focused writing experience. </li>
                        <li>Make corrections easily while you write. </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-center">
                    <video class="vid-video w-100" id="myVideo" playsinline autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" >
                        <source src="/assets/images/features/grammar-checker/Bubble-view.mp4" type="video/mp4">
                        Your browser does not support the video element. Kindly update it to latest version.
                    </video>
                </div>
            </div>
        </div>
    </div>
</section>