<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>AI Proofreading Tool​</h1>
                            <h2>English Proofreading Made Easy With Trinka AI</h2>
                            <p>Make your writing flawless with our AI-powered Proofreading Tool. Correct grammar, spelling errors, sentence structure, punctuation, tone, consistency, and much more with a single click!</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                            <span class="no-credits-tools">No credit card required</span>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/proofreading.png" alt="proofreader banner">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-7">
                    <div class="trinka_cloud_banner_text">
                        <h1 class="yellow-header">Free Online Proofreader</h1>
                        <h2>English Proofreading Made Easy With Trinka AI</h2>
                        <p>Make your writing flawless with our AI-powered English Proofreading Tool. Correct grammar, spelling errors, sentence structure, punctuation, tone, consistency, and much more with a single click!</p>
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" id="autoEditGetStart" target="_blank">Get Started</a>
                        <span class="no-credits-tools">No credit card required</span>
                    </div>
                </div>
                <div class="col-xl-5">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/proofreading.png" alt="proofreader banner">
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="brandLogo_sections mt-0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="brandlogo_heading">
                        <h2>We are Trusted by</h2>
                    </div>
                    <div class="cover_wrapper">
                        <div id="client-logos" class="owl-carousel">
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/MIT.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/StanfordUniversity.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/HarvardMedicalSchool.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/UCL.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/ImperialCollegeLondon.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/UniversityOfManchester.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/Duke.jpg" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/PrincetonUniversity.jpg" alt="universities">
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div class="viewfullList">
                        <a routerLink="/brand-list">View Full List</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="the_best_grammer_sections section-pd">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Best Online AI Proofreader for free</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>From grammar and spelling to sentence structure, tone, and more, Trinka ensures flawless documents. Trinka is an AI-powered writing tool that ensures your content is well-written, free of grammar and spelling errors, and uses correct punctuations. Craft exceptional content effortlessly and focus on delivering a clear message.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/AutoFileEditThumbnail.png" alt="auto file" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Want to know how easy it is to use Trinka's AI Proofreader Tool? Just check out this easy walk-through video and get started right away!</p>
                            <p>Have more questions? Browse through our FAQs  <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Trinka’s Online Proofreader is Ideal for:</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <h3>Researchers</h3>
                        <p>
                            For academics and researchers, Trinka is an essential tool for proofreading and refining scholarly articles, dissertations, and research papers. It assists in maintaining academic integrity and ensures effective communication of ideas.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <h3>Professionals</h3>
                        <p>
                            Whether you're writing reports, presentations, or emails, Trinka can help polish your professional documents. It catches spelling errors, improves sentence structure, and enhances overall professionalism.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <h3>Students</h3>
                        <p>
                            Trinka's proofreader is an invaluable tool for students of all levels. It can assist in editing essays, research papers, and assignments, ensuring impeccable grammar, clarity, and coherence.                        
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <h3>Writers of Various Content</h3>
                        <p>
                            No matter the type of writing you engage in, Trinka is versatile enough to assist you. It can edit personal statements, cover letters, novels, social media posts, and more, helping you convey your message effectively.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="the_best_grammer_sections section-pd pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Additional AI Writing Tools​</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>​Trinka offers immediate writing recommendations and advanced grammar assessments, assisting professionals and scholars in achieving formal, concise, and engaging writing.​​</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="best_grammer_cards">
                        <h3>Advanced Grammar Checks</h3>
                        <div class="content_body_best_grammer">
                            <div class="best_content_text">
                                <p>Trinka's advanced grammar and spelling checks evaluate your writing for advanced grammar errors and suggest language enhancements to improve your writing quality.​</p>
                            </div>
                            <div class="mini_card_grammer">
                                <!-- <h4>Technical word choice</h4> -->
                                <p>Neither the healthy individuals nor the patient <span
                                    class="wrong_text">recognize</span> <span
                                    class="right_text"> recognizes</span> the visual discrepancies.</p>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="best_grammer_cards">
                        <h3>Sentence Structure</h3>
                        <div class="content_body_best_grammer">
                            <div class="best_content_text">
                                <p>It helps you structure your sentences in a more formal manner based on your subject matter, making your writing easier to comprehend for your audience.</p>
                            </div>
                            <div class="mini_card_grammer">
                                <!-- <h4>Syntax</h4> -->
                                <p>The students don't enjoy <span class="wrong_text">to go</span> <span
                                    class="right_text"> going</span> over the same rules again and again.</p>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="best_grammer_cards">
                        <h3>Word Choice</h3>
                        <div class="content_body_best_grammer">
                            <div class="best_content_text">
                                <p>Trinka accurately corrects word choice errors that obscure your writing, making it concise and clear, enabling you to communicate your intent accurately.</p>
                            </div>
                            <div class="mini_card_grammer">
                                <!-- <h4>Advance Spelling</h4> -->
                                <p>One of the reasons is <span class="wrong_text">because</span> <span
                                    class="right_text"> that</span> the fabrication cost <span
                                    class="wrong_text">for</span> <span class="right_text"> of</span> steel
                                members depends upon the material.</p>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="best_grammer_cards">
                        <h3>Advanced Spell Check</h3>
                        <div class="content_body_best_grammer">
                            <div class="best_content_text">
                                <p>Trinka's advanced spell checker knows the context of your writing and helps you pick the right word for your topic. No more awkward spelling errors!</p>
                            </div>
                            <div class="mini_card_grammer">
                                <!-- <h4>Sensitive language</h4> -->
                                <p>The <span class="wrong_text">Bonferoni</span> <span
                                    class="right_text"> Bonferroni</span> test was used to check for
                                statistical significance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-get-trinka></app-get-trinka>

    <div class="testimonial-section text-center section-pd">
        <app-testimonial></app-testimonial>
    </div>
    
    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h3 class="text-white mb-4">What are you waiting for?</h3>
                        <div class="btn-group justify-content-center">
                            <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" class="white-btn" id="grammerSignUpButton">
                                <!-- <i class="fa fa-sign-in"></i> -->
                                <span>Register for Free</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div> 

    <div class="faqQuestionAnwser section-pd" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            What is a proofreading tool?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Proofreading tools carefully check your text, fixing any spelling, grammar, or punctuation mistakes. This helps you communicate your ideas confidently and without the distraction of errors. Try automated proofreading to enhance your writing significantly.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">
                                            Can the Trinka grammar checker replace proofreading? </a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>The quality of the original writing will determine this. A proofreader would be beneficial if you do not speak English and make several complex errors pertaining to logical construction in English. Trinka can fix thousands of grammatical mistakes and complex writing problems. However, AI is limited. If the construction is not logical, it cannot decipher your intended meaning. For those who possess sufficient knowledge of the English language to construct logically correct sentences, Trinka grammar checker can take care of tone, word choice, grammatical constructions, spelling, and a whole lot more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionGCThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                            data-target="#QuestionGCThreeContent" aria-expanded="true"
                                            aria-controls="QuestionGCThreeContent">What platforms/apps can Trinka's grammar checker be used on?</a>
                                    </div>
        
                                    <div id="QuestionGCThreeContent" class="collapse"
                                        aria-labelledby="QuestionGCThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p><b>Trinka’s grammar checker can be used on any web browser.</b> It is also available as a <a  [routerLink]="['/browser-plugin']">browser plug-in</a> for the most popular browsers such as Chrome, Firefox, Safari, and Edge, to help you get grammar corrections on your favourite websites. Trinka is also available on MS Word add-in for premium users.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
    
                                <div class="card">
                                    <div class="card-header" id="QuestionGCFive ">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                            data-target="#QuestionGCFiveContent" aria-expanded="true"
                                            aria-controls="QuestionGCFiveContent">How to check writing online for free?</a>
                                    </div>
        
                                    <div id="QuestionGCFiveContent" class="collapse"
                                        aria-labelledby="QuestionGCFive" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Use Trinka to check your writing online for free. It is an advanced AI-powered tool that makes your writing clear and easy to understand. With Trinka, you can check your text for everything from minor punctuation errors and typos to nuanced grammatical mistakes and phrasing problems. You can check your grammar for free anywhere on the internet with Trinka's <a  [routerLink]="['/browser-plugin']">browser plugin</a> as well as in MS Word using the Trinka add-in for Word.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionGCSix">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                            data-target="#QuestionGCSixContent" aria-expanded="true"
                                            aria-controls="QuestionGCSixContent">How do I check if a sentence is grammatically correct?</a>
                                    </div>
        
                                    <div id="QuestionGCSixContent" class="collapse"
                                        aria-labelledby="QuestionGCSix" data-parent="#faq">
                                        <div class="card-body">
                                            <p>In order to check the grammatical correctness of a sentence, simply paste it in Trinka's spelling and <strong>grammar checker</strong>. Trinka will thoroughly check the sentence for punctuation, grammar, and vocabulary mistakes.</p>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/6Z_PsveCtRQ"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>