<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Language Services, Publishing & Media</h1>
                                <h2>Improve Writing Quality & Editorial Workflows</h2>
                                    <p>Enable your teams to write consistently, publish faster, and boost customer experience. Reduce editing time and costs without sacrificing on clarity, consistency, brand perception, or style.</p>
                                    <div class="btn-group">
                                        <button id="salesContactButtons" data-toggle="modal"
                                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Contact Us</button>
                                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                                    </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/language-services-publishing-and-media.png" alt="Trinka enterprise" class="img-fluid md50">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- <div class="most_suited_for_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="most_united_heading">
                        <h2>
                            <span></span>Industry Focused Solutions for</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="most_suited_point">
                        <ul>
                            <li>Editing & Proofreading Companies</li>
                            <li>Media & Publishing Houses</li>
                            <li>Bloggers & Blogging Websites</li>
                            <li>Translation Service Companies</li>
                            <li>Pre-press Houses</li>
                            <li>Marketing Agencies</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <section class="section-pd trinka-key-features">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Industry Focused Solutions for</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="gt-features three-coloum">
                        <span class="hr-line d-sm-block d-none"></span>
                        <span class="vr-line l25"></span>
                        <span class="vr-line l50 d-sm-none"></span>
                        <span class="vr-line l75"></span>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Editing & Proofreading Companies
                            </h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Media & Publishing Houses</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Bloggers & Blogging Websites</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Translation Service Companies</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Pre-press Houses</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Marketing Agencies</h6>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    </section>

    <div class="brandLogo_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-2">
                    <h2>Trusted by Global Leaders</h2>
                </div>
                <div class="col-sm-10">
                    <div class="cover_wrapper">
                        <div id="client-logos-enterprise" class="owl-carousel">
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/alternet.png" alt="leaders">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/HarperCollinsPublishers.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/scholastic.png" alt="leaders">
                                </div>
                            </div>
                            <!-- <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/RightlyWritten.png" alt="leaders">
                                </div>
                            </div> -->
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/sanchini.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/technica.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/CapstoneWriting.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/evolution_editing.png" alt="leaders">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Enterprise Features that Help Your Teams Write Better</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka assists your teams in creating critical documents efficiently while ensuring quality and compliance
                            with scientific writing conventions.</p>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-none">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/BulkEditingOperations.svg" alt="bulk editing" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Bulk Editing<br class="d-lg-block d-none"/>Operations</h3>
                            <p>Upload your documents in MS Word format and sit back while Trinka edits them in minutes.
                                Just download the edited files and review all changes to finalize your content.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/MedicalSpellCheckicon.svg" alt="medical" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Contextual Spell Check and Dictionary </h3>
                            <p>Trinka's in-built spellchecker knows the difference between ‘from’ and ‘form’ and knows
                                millions of technical words too. Each team member can create personal dictionaries
                                or use a single one to keep all your communication on brand with ease.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/WordCountreductions.png" alt="word count" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Word Count Reductions</h3>
                            <p>Trinka helps you make your point concisely, letting you deliver your message with the
                                right impact. Your teams learn while they write too!</p>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/Suggestionsthatmatchyourtone.png" alt="suggestion" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Suggestions that Match Your Tone</h3>
                            <p>Trinka’s AI offers the right suggestions whether you are writing a casual piece or a
                                formal document. For research documents, you can even set the research type – from
                                original research and reviews to case reports.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/EasyUserManagement.svg" alt="easy management" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Easy User Management</h3>
                            <p>Your team account allows you to add or remove members easily. Manage credits and usage limits in real-time. Access management is easy and secure with Trinka’s granular controls – be it your teams or outsourced talent.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/ppc_icon.png" width="40" height="40" alt="ppc" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Plagiarism<br class="d-lg-block d-none"/>Checker </h3>
                            <p>Highest quality of <a [routerLink]="['/features/plagiarism-check']" class="text-link">plagiarism check</a> with the most advanced text similarity detection algorithm and the  largest paid publication database.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1 ">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/ComprehensiveAnalytics.svg" alt="comprehensive" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Comprehensive Analytics</h3>
                            <p>See how your teams use Trinka or deep-dive into each user’s activity with rich analytics
                                for effective decision making.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/DedicatedSupport.svg" alt="dedicated support" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Dedicated<br class="d-lg-block d-none"/>Support</h3>
                            <p>A dedicated Success Manager is assigned to support your team to get the most out of Trinka.
                                This includes a detailed on-boarding call, help documentation, and video walk-throughs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    </div>
</div>

<div class="whiteListing section-pd">
    <div class="trinka_container">
        <div class="row align-items-center" style="margin-bottom: 40px;">
            <div class="col-md-8 col-sm-8">
                <div class="trinka-title mb-0">
                    <h2>IP Whitelisting</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>IP whitelisting is a security feature to help protect sensitive data and systems from unauthorized access. It is beneficial for your business as it can enhance security by controlling who can view those resources, complying with regulations, improving productivity, and saving money. IP whitelisting is achieved by limiting access only to specific IP addresses from a trusted source.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4">
                <div class="benefitImage">
                    <img src="../../../assets/images/the-benefit-whitepaper.png" width="200" alt="The benefits" class="img-fluid" />
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-8 col-sm-12">
                <div class="trinka-title mb-0">
                    <h3 class="mb-2">Benefits</h3>
                    <p>Our IP whitelisting security feature allows access to Trinka only from specified IP addresses and below are the benefits.</p>
                </div>
            </div>
            
        </div>
        <div class="row match-box">
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Enhanced security <span><img src="../../../assets/images/icons/cyber-security.png" alt="Enhanced security" /></span></h4>
                        <p>Trinka can be accessed only from trusted IP addresses, reducing the risk of unauthorized access and cyber attacks</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Controlled Access <span><img src="../../../assets/images/icons/browser.png" alt="Controlled Access" /></span></h4>
                        <p>Administrators have granular control of who can access Trinka by quickly blocking or unblocking access to specific IP addresses</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Reduced downtime <span><img src="../../../assets/images/icons/clock.png" alt="Reduced downtime" /></span></h4>
                        <p>IP whitelisting controls and reduces the risk of denial-of-service (DoS) attacks and stoppages that can affect genuine users</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Better compliance <span><img src="../../../assets/images/icons/growth.png" alt="Better compliance" /></span></h4>
                        <p>Allows organizations to meet strict industry regulatory compliances, especially where IP whitelisting is a mandate.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row">
            <div class="col-12">
                <div class="section-usecases section-purple rounded-sm">
                    <div class="row justify-content-center">
                        <div class="col-md-11 col-lg-10">
                            <div class="trinka-title mb-0 text-center">
                                <h2>Assistance for All Use Cases</h2>
                                <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                                <p>Discover the true power of Trinka with the help of use cases tailored to specific industries and roles.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards  bt-none">
                                <h3>Powerful Assistance for Content Editors</h3>
                                <p>Let Trinka correct grammar and advanced mechanical issues and let your expert editors focus
                                    on semantics and delivery. This improves output quality and helps streamline the workflow.</p>
                                <ul>
                                    <li>Improved turnaround time on projects</li>
                                    <li>Enhanced editorial quality</li>
                                    <li>Reduced time to publish</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards">
                                <h3>For Marketing<br class="d-lg-block d-none"/>Teams</h3>
                                <p>Enable your teams to create better customer emails, marketing collaterals, communications,
                                    reports, pitches, and more with Trinka’s precise suggestions.</p>
                                <ul>
                                    <li>Avoid embarrassing errors in customer communications</li>
                                    <li>Stay on-brand and on-point with ease</li>
                                    <li>Reduce content delivery times</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3>For Editorial Quality Assessments</h3>
                                <p>Trinka’s writing assessment AI gives you useful insights with customizable scores and category-wise
                                    error breakups.</p>
                                <ul>
                                    <li>Quantitative insights on quality</li>
                                    <li>Assistance with editorial hiring</li>
                                    <li>Learning opportunities with explanations and tips</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3>Automated Grammar Checks After Translations</h3>
                                <p>Translation teams can use Trinka to improve their English translations with precise grammar
                                    corrections and get quality insights.</p>
                                <ul>
                                    <li>Contextual spelling checks.</li>
                                    <li>Technical phrasing and sentence structure improvements.</li>
                                    <li>Automated file editing for high-throughput workflows.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="advanceGrammerSpellCheck_section backgrondSecitons_outer accomplishSections sec2">
    <div class="container">
        <div class="row">
            <div class="col-xl-4">
                <div class="spellcheck_heading EnhancementsHeadingSection">
                    <h2>Assistance for All Use Cases</h2>
                    <div class="HeadingSubLine"></div>
                    <p>Discover the true power of Trinka with the help of use cases tailored to specific industries and roles.</p>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="all_usecases">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="all_usecases_item">
                                <h4>Powerful Assistance
                                    <br>for Content Editors</h4>
                                <p>Let Trinka correct grammar and advanced mechanical issues and let your expert editors focus
                                    on semantics and delivery. This improves output quality and helps streamline the workflow.</p>
                                <ul>
                                    <li>Improved turnaround time on projects</li>
                                    <li>Enhanced editorial quality</li>
                                    <li>Reduced time to publish</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="all_usecases_item">
                                <h4>For Marketing Teams</h4>
                                <p>Enable your teams to create better customer emails, marketing collaterals, communications,
                                    reports, pitches, and more with Trinka’s precise suggestions.</p>
                                <ul>
                                    <li>Avoid embarrassing errors in customer communications</li>
                                    <li>Stay on-brand and on-point with ease</li>
                                    <li>Reduce content delivery times</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="all_usecases_item">
                                <h4>For Editorial
                                    <br>Quality Assessments</h4>
                                <p>Trinka’s writing assessment AI gives you useful insights with customizable scores and category-wise
                                    error breakups.</p>
                                <ul>
                                    <li>Quantitative insights on quality</li>
                                    <li>Assistance with editorial hiring</li>
                                    <li>Learning opportunities with explanations and tips</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="all_usecases_item">
                                <h4>Automated Grammar Checks
                                    <br>After Translations</h4>
                                <p>Translation teams can use Trinka to improve their English translations with precise grammar
                                    corrections and get quality insights.</p>
                                <ul>
                                    <li>Contextual spelling checks.</li>
                                    <li>Technical phrasing and sentence structure improvements.</li>
                                    <li>Automated file editing for high-throughput workflows.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->

<div class="cloud_key_features section-pd list-wrapper-style-2 tailoredSolutions">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Integrate with Your Proprietary Software and Workflows</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka's easy integrations let your teams enjoy all the benefits right within your existing software, making
                        effective writing productive and seamless.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>JavaScript</h3>

                    <p>
                        Enable Trinka for all your team members by
                        <span>simply inserting a small JavaScript (JS) code snippet</span> into your browser or any application.
                    </p>

                    <a [routerLink]="['/enterprise']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>API</h3>
                    <p>
                        Trinka’s powerful API brings Trinka
                        <span>right into your existing applications.</span> With a simple API architecture, it's easy to integrate.</p>

                    <a [routerLink]="['/enterprise/grammar-checker-api']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>On Premise</h3>
                    <p>Ensure effective writing and maximum confidentiality with
                        <span>Trinka hosted on your enterprise infrastructure, be it local or cloud servers.</span>
                    </p>

                    <a [routerLink]="['/enterprise']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 300px;">
                    <h3>Cloud Editor</h3>
                    <p>
                        Use Trinka's full potential anywhere, with the
                        <span>secured web-based application.</span>
                    </p>
                    <a [routerLink]="['/trinkacloud']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 300px;">
                    <h3>MS Word Add-in</h3>
                    <p>
                        Increase your teams’ productivity with writing suggestions delivered
                        <span>within the MS Word.</span>
                    </p>
                    <a  [routerLink]="['/wordplugin']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 300px;">
                    <h3>Browser Plug-ins</h3>
                    <p>
                        Real-time writing assistance on any website through
                        <span>plugins for all your favorite browsers.</span>
                    </p>
                    <a  [routerLink]="['/browser-plugin']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="section-pd section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center">
                    <h2>Personalized Pricing that Works for You</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka offers flexible per-user or usage based pricing to suit your business needs.
                        <br>Switch users and apply usage restrictions in real time, at no extra cost.
                        <br>We'll work with you to create a hassle-free custom contract and single payment option via PO/invoice.</p>
                    <div class="btn-group justify-content-center mt-4">
                        <button data-toggle="modal"
                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Get a Quote</button>
                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-6 col-lg-6">
                <div class="trinka-title">
                    <h3 class="text-white mb-4">Trinka Simplifies Invoices with Support for Local Currency.</h3>
                    <p class="text-white">Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, EUR, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                </div>
                <div class="btn-group">
                    <a [routerLink]="['/local-invoice']" class="white-btn">Check Invoice Options</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="text-center">
                    <img src="../../../assets/images/local-inovice-element.png" alt="local invoice" class="img-fluid" />
                </div>
            </div>
        </div>  
    </div>
</div>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>Safe, Secure, and Trusted</h3>
                        <p>Trinka puts data safety and privacy at the forefront. All your data is strongly encrypted and securely
                            stored - no one else has access to your data. We offer unique plans that completely eliminate
                            saving any data once you receive writing suggestions.
                        </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>About Trinka</h3>
                    <p>Trinka uses the latest Machine Learning (ML) and Natural Language Processing (NLP) technologies to
                        correct grammar errors and suggest language enhancements. Our AI is trained on millions of well-written
                        papers and articles covering 1300+ subject areas including medical, life sciences, physical sciences,
                        engineering, humanities, business, and arts to give you the most relevant suggestions.</p>
                    <p>Trinka is developed by a team of linguists, editors, data scientists, and engineers with a deep desire
                        to create a future with barrier-free communication.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/about-ai.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>


<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Transform Your Business Communication with Trinka</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup"
                    class="white-btn" type="submit">Get a Quote</button>
                </div>
            </div>
        </div>  
    </div>
</div>

<app-enterprise-modal></app-enterprise-modal>