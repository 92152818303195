import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
declare function ire(string,{}): any;

@Component({
  selector: 'app-windows-desktop-app',
  templateUrl: './windows-desktop-app.component.html',
  styleUrls: ['./windows-desktop-app.component.scss']
})
export class WindowsDesktopAppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Free Grammar Checker App for Your Desktop - Trinka Windows App');
    this.metaTagService.updateTag({ name: 'description', content: 'Use Trinka’s Windows App to get professional grammar corrections and suggestions as you write in Windows applications.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Grammar Checker App for Your Desktop - Trinka Windows App' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Use Trinka’s Windows App to get professional grammar corrections and suggestions as you write in Windows applications.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/windows-desktop-app' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Grammar Checker App for Your Desktop - Trinka Windows App' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Use Trinka’s Windows App to get professional grammar corrections and suggestions as you write in Windows applications." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/windows-desktop-app' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/windows-desktop-app' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/windows-desktop-app' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/windows-desktop-app' });


    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.indexOf("WOW64") != -1 ||
        navigator.userAgent.indexOf("Win64") != -1) {
        document.getElementById("windowAppInfo64bit").innerHTML = '<a  style="color:#FFF" href="https://s3.amazonaws.com/trinka-win-native/64bit/TrinkaForWindows.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="whitepaper" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
        document.getElementById("windowAppInfo64bit1").innerHTML = '<a  style="color:#FFF" href="https://s3.amazonaws.com/trinka-win-native/64bit/TrinkaForWindows.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="whitepaper" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
        document.getElementById("windowAppInfo64bit2").innerHTML = '<a href="https://s3.amazonaws.com/trinka-win-native/64bit/TrinkaForWindows.msi" target="_blank" class="text-link">download</a>';
      } else {
        document.getElementById("windowAppInfo64bit").innerHTML = '<a style="color:#FFF" href="https://s3.amazonaws.com/trinka-win-native/32bit/TrinkaForWindows.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="whitepaper" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
        document.getElementById("windowAppInfo64bit1").innerHTML = '<a style="color:#FFF" href="https://s3.amazonaws.com/trinka-win-native/32bit/TrinkaForWindows.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="whitepaper" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
        document.getElementById("windowAppInfo64bit2").innerHTML = '<a href="https://s3.amazonaws.com/trinka-win-native/32bit/TrinkaForWindows.msi" target="_blank" class="text-link">download</a>';
      }
    }
    
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/u5OE774DAMQ?si=CSbiZjrRLcWpLhw4" width="100%" height="450"></iframe>');
        }
      });

    }
  }

}
