<div class="top-tools-tab-mobile" id="tabs-tools" role="tablist">
    <a [routerLink]="['/grammar-checker']" role="tab" aria-selected="false" tabindex="-1" id="tab-grammar">
        <img src="../../../../assets/images/menu-icons/grammar-checker.svg" alt="grammar-checker">
        <p>Grammar Checker</p>
    </a>
    <a [routerLink]="['/paraphrasing-tool']" role="tab" aria-selected="false" tabindex="-1" id="tab-paraphraser">
        <img src="../../../../assets/images/menu-icons/pharaphraser.svg" alt="Paraphraser">
        <p>Paraphraser</p>
    </a>
    <a [routerLink]="['/journal-finder']" role="tab" aria-selected="true" class="active"  tabindex="0" id="tab-jorunal-finder">
      <img src="../../../../assets/images/menu-icons/journal-finder.svg" alt="Journal Finder">
      <p>Journal Finder</p>
    </a>
    <a [routerLink]="['/ai-content-detector']" role="tab" aria-selected="false" tabindex="-1" id="tab-ai-detector">
        <img src="../../../../assets/images/menu-icons/ai-detector.svg" alt="AI Detector">
        <p>AI Detector</p>
    </a>
    <a [routerLink]="['/academic-phrasebank']" role="tab" aria-selected="true"  tabindex="0" id="tab-phrasebank">
        <img src="../../../../assets/images/menu-icons/phrasebank.svg" alt="Academic Phrasebank">
        <p>Academic Phrasebank</p>
    </a>
</div>
  
<app-springer-journal-tool></app-springer-journal-tool>

<section class="section-how-it-works section-pd-sm bg-white">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="trinka-title text-center">
                    <h2>Find the Right Springer Journal for you</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">Choosing the right journal for your research can enhance its reach and impact. Begin by exploring Springer Nature journals that align with your research topic and audience. Check their scope, focus areas, and recent publications to assess compatibility.</p>
                    <p class="w-100">Also, consider factors such as publication speed, open access availability, and indexing in databases. Tools like Trinka’s Journal Finder can simplify this process by matching your manuscript to suitable journals based on your abstract. This approach increases the likelihood of acceptance and ensures your work reaches the appropriate audience.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <ul>
                    <li>
                        <div class="login_circle_section bg-light-purple">
                            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"></path> <path d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                        </div>
                        <h3>Enter <br class="d-lg-block d-none"/>Abstract</h3>
                        <p class="mt-2">Provide the abstract of your manuscript, and Trinka will analyze it to identify key concepts and focus areas.</p>
                    </li>
                    <li>
                        <span class="arrow">
                            <svg fill="#a8a8a8" width="64px" height="64px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#a8a8a8"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.489 31.975c-0.271 0-0.549-0.107-0.757-0.316-0.417-0.417-0.417-1.098 0-1.515l14.258-14.264-14.050-14.050c-0.417-0.417-0.417-1.098 0-1.515s1.098-0.417 1.515 0l14.807 14.807c0.417 0.417 0.417 1.098 0 1.515l-15.015 15.022c-0.208 0.208-0.486 0.316-0.757 0.316z"></path> </g></svg>
                        </span>
                        <div class="login_circle_section">
                            <svg width="48px" height="48px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#7A28A0" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><polyline points="33.85 55.28 12.44 55.28 12.44 19.61 24.39 6.75 50.05 6.75 50.05 38.42"></polyline><polyline points="24.41 6.75 24.39 19.61 12.44 19.61"></polyline><line x1="17.59" y1="46.68" x2="29.6" y2="46.68"></line><line x1="17.59" y1="40.15" x2="31.6" y2="40.15"></line><line x1="17.59" y1="34.05" x2="39.6" y2="34.05"></line><line x1="17.59" y1="27.95" x2="44.6" y2="27.95"></line><circle cx="41.76" cy="47.06" r="7.66"></circle><line x1="46.96" y1="52.69" x2="52.73" y2="58.95"></line></g></svg>
                        </div>
                        <h3>Find Related <br class="d-lg-block d-none"/>Journals</h3>
                        <p class="mt-2">Trinka will suggest a list of Springer journals that align with your research topic, based on the scope and relevance of your abstract.</p>
                        <span class="arrow arrow1">
                            <svg fill="#a8a8a8" width="64px" height="64px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#a8a8a8"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.489 31.975c-0.271 0-0.549-0.107-0.757-0.316-0.417-0.417-0.417-1.098 0-1.515l14.258-14.264-14.050-14.050c-0.417-0.417-0.417-1.098 0-1.515s1.098-0.417 1.515 0l14.807 14.807c0.417 0.417 0.417 1.098 0 1.515l-15.015 15.022c-0.208 0.208-0.486 0.316-0.757 0.316z"></path> </g></svg>
                        </span>
                    </li>
                    <li>
                        <div class="login_circle_section">
                            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 3H5.25V21H12V19.5H6.75V4.5H15.4393L17.25 6.31066V13.5H18.75V5.68934L16.0607 3ZM15.75 9.75H8.25V8.25H15.75V9.75ZM15.75 12.75H8.25V11.25H15.75V12.75ZM12 15.75H8.25V14.25H12V15.75ZM15.0578 20.569L19.2845 16.2762L18.2157 15.2238L15.0578 18.431L13.2845 16.6301L12.2157 17.6825L15.0578 20.569Z" fill="#7A28A0"></path> </g></svg>
                        </div>
                        <h3>Select/Analyze the<br class="d-lg-block d-none"/> Best Result</h3>
                        <p class="mt-2">Explore the recommended journals, compare related articles, and filter options by region, impact factor range, or other preferences to choose the best fit for your work.</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="howItWorks section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="list-wrapper-image">
                    <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                        <img src="/assets/images/JournalFinderThumbnail.jpg" loading="lazy" decoding="async" alt="video thumbnail" class="img-fluid eposter-img">
                    </a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="list-wrapper-content-2">
                    <div class="trinka-title mb-0">
                        <h2>See How It Works</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                        <p>Using the Trinka Springer Journal Finder is simple and convenient. Just enter your article abstract, and the tool will analyze it against a comprehensive database of Springer journals to identify the most relevant options for your research.</p>
                        <p class="mb-0">Still have questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section-pd bg-white">
    <div class="trinka_container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="trinka-title">
                    <h2>How Can Trinka’s Springer Journal Finder Help You</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>Trinka’s Journal Finder simplifies the process of selecting the right journal for your research. It analyzes your manuscript and provides tailored suggestions, ensuring that a suitable match is found quickly, and efficiently.</p>
                    <p>The tool considers various factors like cite score, impact factor, geography, and H-index. This personalized approach saves time, allowing you to focus on what really matters—your research. With Trinka's intuitive interface, navigating through options becomes effortless, making it an essential resource for researchers aiming for successful publication in reputable journals.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image features-img">
                    <img src="/assets/images/features/s1.svg" loading="lazy" decoding="async" class="img-fluid" alt="journal-finder">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="cloud_key_features section-pd pt-0 list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Key Features of Trinka’s Journal Finder for Springer</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-70">Trinka’s Journal Finder for Springer is designed to simplify your publishing journey. It offers an intuitive interface that allows researchers to input their article details easily, resulting in tailored journal suggestions.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none pr-0">
                    <img src="../../../assets/images/icons/AI-power.svg" loading="lazy" decoding="async" alt="AI-Powered Journal Recommendations">
                    <h3>AI-Powered<br class="d-md-block d-none"/> Suggestions</h3>
                    <p>Matches your manuscript abstract with relevant Springer journals based on our proprietary AI algorithms.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards pr-0">
                    <img src="../../../assets/images/icons/all-subjects.svg" height="40" loading="lazy" decoding="async" alt="Works for all Subjects Areas">
                    <h3>Works Across All Disciplines</h3>
                    <p>Supports a wide range of subjects with an extensive database of published papers.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards pr-0">
                    <img src="../../../assets/images/icons/large-database.svg" height="40" loading="lazy" decoding="async" alt="Large Database of Scientific Publications">
                    <h3>Comprehensive Journal Database</h3>
                    <p>Uses a large database of scientific publications from sources such as OpenAlex.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 pr-0">
                    <img src="../../../assets/images/icons/publication-trend.svg" height="40" loading="lazy" decoding="async" alt="Publication Trends to Understand Citability">
                    <h3>Understand Publication Trends</h3>
                    <p>Shows publications of your research field over time, helping you gauge the visibility of your research.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 pr-0">
                    <img src="../../../assets/images/icons/similar.svg" height="40" loading="lazy" decoding="async" alt="Similar Articles for Perfect Scope Match">
                    <h3>Find Similar Articles</h3>
                    <p>View similar papers to ensure a perfect scope match and avoid desk rejections.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 pr-0">
                    <img src="../../../assets/images/icons/search-intutive.svg" height="40" loading="lazy" decoding="async" alt="Intuitive Filters for Refined Search">
                    <h3>Smart<br class="d-lg-block d-none"/> Filters</h3>
                    <p>Refine your search by subject, open access, impact factor, and more for precise results.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-global-leaders></app-global-leaders>

<div class="section-pd">
    <div class="trinka_container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Best Springer Journal Finder</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">The Trinka Springer Nature Journal Finder is a helpful tool for researchers seeking the right journal for their work. By analyzing your manuscript abstract, it matches your content with Springer’s database, providing tailored suggestions to help you find journals that align with your research. This approach increases the chances of successful submissions by identifying suitable options.</p>
                    
                    <div class="btn-group justify-content-center mt-4">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" class="filled-btn" id="grammerSignUpButton">
                            <!-- <i class="fa fa-sign-in"></i> -->
                            <span>Sign Up for Free</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cloud_key_features section-pd list-wrapper-style-2 section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Who can use the Springer journal finder?</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>The Springer Journal Finder is a valuable tool for researchers across various disciplines. Whether you are a seasoned academic or a budding scholar, it caters to anyone looking to publish their work in reputable journals.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>Researchers</h3>
                    <p>
                        Designed for researchers across disciplines, this tool provides personalized journal recommendations based on your research area, making it easier to share findings with the right audience.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>Publisher</h3>
                    <p>
                        Publishers can use this tool to connect their content with suitable journals, enhancing visibility and streamlining the submission process to strengthen their portfolios.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <h3>Graduate Student</h3>
                    <p>
                        For students navigating academic publishing, the Springer Journal Finder offers valuable guidance in identifying journals that match their research topics, making the process less overwhelming.                        
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>FAQs on Springer Nature Journal Finder</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                        What is Springer journal finder?
                                    </a>
                                </div>

                                <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The Springer Journal Suggester is an academic specific research tool which enables users to find and choose the top-suited springer journal for publishing their research.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                        aria-controls="QuestionTwoContent">
                                        How to find a suitable journal for publication in Springer?
                                    </a>
                                </div>

                                <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Use our Trinka Springer Journal finder, if you are looking for the right journal to publish your article in Springer. Simply enter your article's abstract or description, and our tool will recommend the relevant Springer journals that align with your research.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                        aria-controls="QuestionThreeContent">
                                        What factors should I consider when selecting a journal?
                                    </a>
                                </div>

                                <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>After receiving recommendations, review each journal’s:</p>
                                        <ul>
                                            <li>Scope and focus areas.</li>
                                            <li>Impact factor.</li>
                                            <li>Region.</li>
                                            <li>Cite Score.</li>
                                            <li>Submission guidelines.</li>
                                            <li>Open access options.</li>
                                            <li>Publication speed and indexing databases.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                        aria-controls="QuestionFourContent">
                                        What is the purpose of the Springer Journal Finder?
                                    </a>
                                </div>

                                <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>It helps researchers identify suitable Springer journals for their articles based on their manuscript’s abstract.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>


                    <iframe  class="youtubeVideoForTryDemo" id="iframe"  width="100%" height="450" src="https://www.youtube.com/embed/fuWNwHYFPFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>