import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-make-us-stand-out',
  templateUrl: './what-make-us-stand-out.component.html',
  styleUrls: ['./what-make-us-stand-out.component.scss']
})
export class WhatMakeUsStandOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
