<section class="section-pd">
    <div class="trinka_container">
        <div class="secure-wrapper">
            <div class="row align-items-center match-box">
                <div class="col-md-6 col-lg-7 dflex">
                    <div class="trinka-title mb-0">
                        <h2>Your Writing is Personal- Keep it Private</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                        <p>With Sensitive Data Plan, protect your data to meet <br class="d-lg-block d-none"/>the privacy and compliance needs.</p>
                        <ul>
                            <li>No data storage with real-time deletion</li>
                            <li>No AI training on your data</li>  
                            <li>Full data control</li>     
                        </ul>
                        <div class="btn-group">
                            <a href="/enterprise/sensitive-data-plan" class="filled-btn">Know More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5 dflex ">
                    <div class="text-center">
                        <!-- <picture>
                            <source srcset="../../../assets/images/homepage/data-security.webp" type="image/webp">
                            <img src="../../../assets/images/homepage/data-security.jpg" loading="lazy" decoding="async" alt="data-security">
                        </picture> -->
                        <img src="../../../assets/images/homepage/data-security.svg" class="img-fluid" loading="lazy" decoding="async" alt="data-security">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>