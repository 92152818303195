import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
import { isPlatformBrowser } from '@angular/common';
import * as $ from 'jquery';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-grammar-checker-features',
  templateUrl: './grammar-checker-features.component.html',
  styleUrls: ['./grammar-checker-features.component.scss']
})
export class GrammarCheckerFeaturesComponent implements OnInit {
  
  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService, 
    public loadScript: LoadScriptService
  ) { 
  }
  
  ngOnInit() {
    this.titleService.setTitle("Trinka AI | Transform Your Writing with Advanced Grammar Checker");
    this.metaTagService.updateTag({ name: 'description', content: " Elevate your writing with Trinka AI, an AI-powered grammar checker and language enhancement tool for refined tone, structure, word choice, and readability." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Trinka AI | Transform Your Writing with Advanced Grammar Checker" });
    this.metaTagService.updateTag({ property: 'og:description', content: " Elevate your writing with Trinka AI, an AI-powered grammar checker and language enhancement tool for refined tone, structure, word choice, and readability." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/grammar-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/WritingMoreEffective.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka AI | Transform Your Writing with Advanced Grammar Checker' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: " Elevate your writing with Trinka AI, an AI-powered grammar checker and language enhancement tool for refined tone, structure, word choice, and readability." });  
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/WritingMoreEffective.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/grammar-checker' });
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../../assets/js/home_client_slider.js', function () { });
    }
  }

}
