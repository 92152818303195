<div class="heroSection">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-lg-6 col-md-6">
                <div class="hero-content">
                    <h2 class="trinka-subtitle-tag">Privacy-first AI Writing Assistant</h2>
                    <h1>Grammar and Writing Done Right</h1>
                    <p>Trinka is an online grammar checker and language correction AI tool for <span>academic, technical, and formal writing</span> that protects your private data.</p>
                    <div class="btn-group">
                        <a id="homeSignUpforFree" href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Start for Free</a>
                        <span class="no-credits">No credit card required</span>
                    </div>
                    <span class="banner-blur">
                        <img src="../../../assets/images/homepage/banner-blur.svg" width="1333" height="1312" alt="background-banner" />
                    </span>
                    <!-- <span class="circle-s circle1"></span>
                    <span class="circle-s circle2"></span>
                    <span class="circle-s circle3"></span> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="home_banner">
                    <div class="trinka-banner-content">
                        <h2>
                            #Proprietary patented <br/>AI technology
                        </h2>
                        <!-- <p (click)="scrollToElementWithOffset('grammarchecker', 200)"> -->
                        <a [routerLink]="['/grammar-checker']">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                <rect x="0.628906" width="30" height="30" rx="15" fill="#F3E8FF"/>
                                <path d="M11.211 15.5396L10.484 13.4756H7.64212L6.91513 15.5396H5.62891L8.39454 8.0765H9.74685L12.5074 15.5396H11.211ZM10.1688 12.4283L9.46215 10.3948C9.43504 10.3066 9.39606 10.1829 9.34523 10.0236C9.29439 9.86095 9.24355 9.69657 9.19271 9.53049C9.14187 9.36103 9.0995 9.21868 9.06561 9.10345C9.03172 9.24241 8.98935 9.39831 8.93851 9.57116C8.89107 9.74063 8.84362 9.89992 8.79617 10.049C8.75211 10.1982 8.71821 10.3134 8.69449 10.3948L7.98274 12.4283H10.1688Z" fill="#7A28A0"/>
                                <path d="M13.4886 8.10701H15.7001C16.6592 8.10701 17.3828 8.24597 17.8709 8.52388C18.359 8.8018 18.603 9.28138 18.603 9.96262C18.603 10.2473 18.5521 10.5049 18.4505 10.7354C18.3522 10.9625 18.2081 11.1506 18.0183 11.2997C17.8285 11.4454 17.5947 11.5437 17.3168 11.5946V11.6454C17.6048 11.6962 17.8607 11.786 18.0844 11.9148C18.3115 12.0436 18.4894 12.2283 18.6182 12.469C18.7504 12.7096 18.8165 13.0214 18.8165 13.4044C18.8165 13.8586 18.708 14.2449 18.4911 14.5635C18.2776 14.8821 17.9709 15.1245 17.5709 15.2905C17.1744 15.4566 16.7033 15.5396 16.1576 15.5396H13.4886V8.10701ZM14.7087 11.1726H15.878C16.4305 11.1726 16.8135 11.0828 17.027 10.9031C17.2405 10.7235 17.3473 10.4608 17.3473 10.1151C17.3473 9.76266 17.2202 9.50846 16.966 9.35256C16.7152 9.19665 16.3152 9.1187 15.7662 9.1187H14.7087V11.1726ZM14.7087 12.1589V14.5178H15.9949C16.5643 14.5178 16.9643 14.4076 17.1947 14.1873C17.4252 13.967 17.5404 13.6688 17.5404 13.2926C17.5404 13.0621 17.4879 12.8621 17.3828 12.6927C17.2812 12.5232 17.1134 12.3927 16.8795 12.3012C16.6457 12.2063 16.3305 12.1589 15.9339 12.1589H14.7087Z" fill="#7A28A0"/>
                                <path d="M23.5191 9.03736C23.1632 9.03736 22.8446 9.10175 22.5633 9.23054C22.2854 9.35934 22.0499 9.54574 21.8567 9.78977C21.6635 10.0304 21.516 10.3236 21.4144 10.6693C21.3127 11.0116 21.2618 11.398 21.2618 11.8284C21.2618 12.4046 21.3432 12.9011 21.5059 13.318C21.6686 13.7315 21.916 14.0501 22.2481 14.2738C22.5803 14.4941 23.0005 14.6042 23.5089 14.6042C23.8241 14.6042 24.1309 14.5737 24.4291 14.5127C24.7274 14.4517 25.0358 14.3687 25.3544 14.2636V15.3007C25.0527 15.4193 24.7477 15.5058 24.4393 15.56C24.1309 15.6142 23.775 15.6413 23.3717 15.6413C22.6125 15.6413 21.9821 15.4837 21.4805 15.1685C20.9822 14.8533 20.6094 14.4093 20.362 13.8365C20.118 13.2638 19.996 12.5927 19.996 11.8233C19.996 11.2607 20.0739 10.7455 20.2298 10.2778C20.3857 9.81011 20.6128 9.40679 20.9111 9.06786C21.2093 8.72554 21.5771 8.46288 22.0143 8.27986C22.4549 8.09345 22.9582 8.00024 23.5242 8.00024C23.897 8.00024 24.263 8.04261 24.6223 8.12734C24.9849 8.20868 25.3205 8.32561 25.6289 8.47813L25.2019 9.48474C24.9443 9.36273 24.6748 9.25766 24.3935 9.16954C24.1122 9.08142 23.8207 9.03736 23.5191 9.03736Z" fill="#7A28A0"/>
                                <path d="M12.5684 19.4886L14.5106 22.0001L18.6895 17.0024" stroke="#7A28A0" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Grammar Checker
                        </a>
                        <!-- <p (click)="scrollToElementWithOffset('paraphraser', 300)"> -->
                        <a [routerLink]="['/paraphrasing-tool']">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="30" height="30" rx="15" fill="#F3E8FF"/>
                                <path d="M22.4998 9.375H16.6665C16.3248 9.375 16.0415 9.09167 16.0415 8.75C16.0415 8.40833 16.3248 8.125 16.6665 8.125H22.4998C22.8415 8.125 23.1248 8.40833 23.1248 8.75C23.1248 9.09167 22.8415 9.375 22.4998 9.375Z" fill="#7A28A0"/>
                                <path d="M22.4998 13.5417H16.6665C16.3248 13.5417 16.0415 13.2584 16.0415 12.9167C16.0415 12.5751 16.3248 12.2917 16.6665 12.2917H22.4998C22.8415 12.2917 23.1248 12.5751 23.1248 12.9167C23.1248 13.2584 22.8415 13.5417 22.4998 13.5417Z" fill="#7A28A0"/>
                                <path d="M22.5 17.7083H7.5C7.15833 17.7083 6.875 17.4249 6.875 17.0833C6.875 16.7416 7.15833 16.4583 7.5 16.4583H22.5C22.8417 16.4583 23.125 16.7416 23.125 17.0833C23.125 17.4249 22.8417 17.7083 22.5 17.7083Z" fill="#7A28A0"/>
                                <path d="M22.5 21.875H7.5C7.15833 21.875 6.875 21.5917 6.875 21.25C6.875 20.9083 7.15833 20.625 7.5 20.625H22.5C22.8417 20.625 23.125 20.9083 23.125 21.25C23.125 21.5917 22.8417 21.875 22.5 21.875Z" fill="#7A28A0"/>
                                <path d="M11.5998 13.9583H9.23317C7.94984 13.9583 7.2915 13.3083 7.2915 12.0166V9.64992C7.2915 8.36659 7.9415 7.70825 9.23317 7.70825H11.6082C12.8915 7.70825 13.5498 8.35825 13.5498 9.64992V12.0249C13.5415 13.3083 12.8915 13.9583 11.5998 13.9583ZM9.23317 8.95825C8.6415 8.95825 8.5415 9.05825 8.5415 9.64992V12.0249C8.5415 12.6166 8.6415 12.7166 9.23317 12.7166H11.6082C12.1998 12.7166 12.2998 12.6166 12.2998 12.0249V9.64992C12.2998 9.05825 12.1998 8.95825 11.6082 8.95825H9.23317Z" fill="#7A28A0"/>
                            </svg> 
                            Paraphraser
                        </a>
                        <!-- <p (click)="scrollToElementWithOffset('plagiarism-check', 280)"> -->
                        <a [routerLink]="['/features/plagiarism-check']">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <rect width="30" height="30" rx="15" fill="#F3E8FF"/>
                                <path d="M6.11523 16H23.8846" stroke="#7A28A0" stroke-width="0.8" stroke-linecap="round"/>
                                <path d="M8.9209 21.079V8.921C8.9209 7.88797 9.75834 7.05054 10.7913 7.05054H17.0222C17.5825 7.05054 18.1132 7.30167 18.4685 7.73487L20.6547 10.4006C20.929 10.735 21.0789 11.1542 21.0789 11.5867V21.079C21.0789 22.112 20.2415 22.9495 19.2084 22.9495H10.7913C9.75834 22.9495 8.9209 22.112 8.9209 21.079Z" stroke="#7A28A0" stroke-width="0.785594"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8085 11.4234C15.8085 11.6434 15.7652 11.8611 15.681 12.0643C15.5968 12.2675 15.4735 12.4521 15.318 12.6076C15.1625 12.7631 14.9779 12.8864 14.7747 12.9706C14.5716 13.0547 14.3538 13.0981 14.1339 13.0981C13.914 13.0981 13.6962 13.0547 13.4931 12.9706C13.2899 12.8864 13.1053 12.7631 12.9498 12.6076C12.7942 12.4521 12.6709 12.2675 12.5867 12.0643C12.5026 11.8611 12.4593 11.6434 12.4593 11.4234C12.4593 10.9794 12.6357 10.5534 12.9498 10.2393C13.2638 9.92529 13.6898 9.74886 14.1339 9.74886C14.578 9.74886 15.0039 9.92529 15.318 10.2393C15.632 10.5534 15.8085 10.9794 15.8085 11.4234ZM15.4779 13.1057C15.0479 13.4492 14.5028 13.615 13.9544 13.5691C13.4061 13.5232 12.8961 13.2691 12.5292 12.8589C12.1623 12.4488 11.9665 11.9137 11.9818 11.3636C11.9971 10.8136 12.2225 10.2902 12.6115 9.90112C13.0007 9.51201 13.524 9.28665 14.074 9.27134C14.6241 9.25603 15.1592 9.45192 15.5694 9.81878C15.9795 10.1856 16.2337 10.6956 16.2795 11.244C16.3254 11.7924 16.1596 12.3375 15.8161 12.7675L17.174 14.1248C17.1962 14.1471 17.2139 14.1735 17.2259 14.2025C17.238 14.2316 17.2441 14.2627 17.2441 14.2942C17.2441 14.3256 17.238 14.3568 17.2259 14.3858C17.2139 14.4149 17.1962 14.4413 17.174 14.4635C17.1518 14.4858 17.1253 14.5035 17.0962 14.5155C17.0672 14.5275 17.036 14.5337 17.0046 14.5337C16.9732 14.5337 16.942 14.5275 16.913 14.5155C16.8839 14.5035 16.8575 14.4858 16.8353 14.4635L15.4779 13.1057Z" fill="#7A28A0"/>
                            </svg>
                            Plagiarism Checker
                        </a>
                    </div>
                    <div class="home_img">
                        <picture>
                          <source srcset="/assets/images/human-page-banner.webp" type="image/webp">
                          <img src="/assets/images/human-page-banner.png" alt="human checking grammar errors">
                        </picture> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-global-leaders></app-global-leaders>

<section class="section-pd allFeaturesSection">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="trinka-title text-center ">
                    <h2>Your Writing is Personal – Keep it Private</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">Trinka’s Grammar Checker prioritizes your data privacy while enhancing the clarity and impact of your writing. With advanced AI and robust security features, write confidently knowing your data is under your control.</p>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="LanguageToggleButton  mb-20 justify-content-center">
                    <div class="language-toggle-btn bg-white">
                        <p>English</p>
                        <label class="switch" style="margin:0 8px;">
                            <input type="checkbox" [(ngModel)]="showLanguages">
                        <span class="slider-rd round"></span>
                        </label>
                        <p>Spanish</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="features-container" id="grammarchecker">
                    <span class="shape"></span>
                    <div id="tile-1">
                        <ul class="nav nav-pills nav-justified" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" (click)="onTabClick($event)" data-toggle="pill" href="#grammarChecker" role="tab" aria-selected="true">Grammar Checker</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" (click)="onTabClick($event)" data-toggle="pill" href="#proofread" role="tab" aria-selected="false">Proofread File</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" (click)="onTabClick($event)" data-toggle="pill" href="#latex" role="tab" aria-selected="false">LaTeX Proofreader</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" (click)="onTabClick($event)" data-toggle="pill" href="#consistencyCheck" role="tab" aria-selected="false">Consistency Check</a>
                            </li>
                            <div class="slider"></div>
                        </ul>
                    </div>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="grammarChecker" class="container tab-pane active">
                            <p>Trinka’s Grammar Checker covers everything from word choice, usage and style, and word count reduction to advanced grammar checks for English and Spanish texts.</p>
                            <div class="image" [ngClass]="{'hidden': showLanguages}">
                                <!-- <img src="../../../assets/images/homepage/grammar-checker.svg" loading="lazy" decoding="async" alt="grammar checker"  /> -->
                                <picture>
                                    <source srcset="../../../assets/images/homepage/grammar-checker.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/grammar-checker.png" width="782" height="460" loading="lazy" decoding="async" alt="grammar-checker">
                                </picture>
                            </div>
                            <div class="image" [ngClass]="{'hidden': !showLanguages}">
                                <!-- <img src="../../../assets/images/homepage/grammar-checker.svg" loading="lazy" decoding="async" alt="grammar checker"  /> -->
                                <picture>
                                    <source srcset="../../../assets/images/homepage/spanish/grammar-checker.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/spanish/grammar-checker.png" width="782" height="460" loading="lazy" decoding="async" alt="grammar-checker">
                                </picture>
                            </div>
                        </div>
                        <div id="proofread" class="tab-pane fade">
                            <p>Proofread entire English or Spanish document in MS Word with Trinka. Retain original formatting, get a language score, apply style guide preferences, and download your file with track changes.</p>
                            <div class="image" [ngClass]="{'hidden': showLanguages}">
                                <picture>
                                    <source srcset="../../../assets/images/homepage/proofread.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/proofread.png" width="762" height="460" loading="lazy" decoding="async" alt="proofread file">
                                </picture>
                                <!-- <img src="../../../assets/images/homepage/proofread.svg" loading="lazy" decoding="async" alt="proofread" /> -->
                                <div class="arrow-clip">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="233" height="201" viewBox="0 0 233 201" fill="none">
                                        <path d="M34.7918 0.358398L71.9196 40.5096L45.7084 92.0693L100.977 82.1605L126.132 142.887L233 2.76102L34.7918 0.358398ZM27.9951 87.2802L38.2828 78.8388L25.0039 83.0393L23.6172 81.1043L29.1238 75.775L21.9402 78.6695C-5.52877 89.3362 -25.0078 115.918 -25.0078 147.08C-25.0177 157.036 -22.9884 166.889 -19.0448 176.031C-15.1012 185.173 -9.32697 193.411 -2.07803 200.236C-8.34641 189.246 -11.6343 176.808 -11.616 164.156C-11.616 133.382 7.8711 105.953 34.7837 95.1653L38.1942 86.9416L30.1317 90.4649L27.8661 87.4011L27.9951 87.2802ZM74.9027 66.1161L92.0839 35.8011L193.115 12.1619L74.9027 66.1161Z" fill="#763393"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="image" [ngClass]="{'hidden': !showLanguages}">
                                <picture>
                                    <source srcset="../../../assets/images/homepage/spanish/proofread.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/spanish/proofread.png" width="762" height="460" loading="lazy" decoding="async" alt="proofread file">
                                </picture>
                                <!-- <img src="../../../assets/images/homepage/proofread.svg" loading="lazy" decoding="async" alt="proofread" /> -->
                                <div class="arrow-clip">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="233" height="201" viewBox="0 0 233 201" fill="none">
                                        <path d="M34.7918 0.358398L71.9196 40.5096L45.7084 92.0693L100.977 82.1605L126.132 142.887L233 2.76102L34.7918 0.358398ZM27.9951 87.2802L38.2828 78.8388L25.0039 83.0393L23.6172 81.1043L29.1238 75.775L21.9402 78.6695C-5.52877 89.3362 -25.0078 115.918 -25.0078 147.08C-25.0177 157.036 -22.9884 166.889 -19.0448 176.031C-15.1012 185.173 -9.32697 193.411 -2.07803 200.236C-8.34641 189.246 -11.6343 176.808 -11.616 164.156C-11.616 133.382 7.8711 105.953 34.7837 95.1653L38.1942 86.9416L30.1317 90.4649L27.8661 87.4011L27.9951 87.2802ZM74.9027 66.1161L92.0839 35.8011L193.115 12.1619L74.9027 66.1161Z" fill="#763393"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div id="latex" class="container tab-pane fade">
                            <p>Eliminate grammar errors in your LaTeX document without impacting your TeX code. Get a language quality score along with instant corrections, track changes.</p>
                            <div class="image">
                                <picture>
                                    <source srcset="../../../assets/images/homepage/latex.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/latex.png" width="763" height="460" loading="lazy" decoding="async" alt="latex grammar checker">
                                </picture>
                                <!-- <img src="../../../assets/images/homepage/latex.svg" loading="lazy" decoding="async" alt="latex" /> -->
                            </div>
                        </div>
                        <div id="consistencyCheck" class="container tab-pane fade">
                            <p>Trinka identifies several inconsistencies in your English and Spanish content like spellings, hyphens and dashes, characters with accent, and more to make your writing look professional.</p>
                            <div class="image" [ngClass]="{'hidden': showLanguages}">
                                <picture>
                                    <source srcset="../../../assets/images/homepage/consistency-check.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/consistency-check.png" width="763" height="460" loading="lazy" decoding="async" alt="consistency-checker">
                                </picture>
                                <!-- <img src="../../../assets/images/homepage/consistency-check.svg" class="img-fluid" loading="lazy" decoding="async" alt="consistency checker" /> -->
                                <div class="arrow-clip">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="269" height="254" viewBox="0 0 269 254" fill="none">
                                        <path d="M33.673 228.702L72.6369 215.684L79.655 219.102C95.8963 226.993 114.458 231.198 134.726 231.198C201.762 231.198 249.777 183.235 249.777 125.596C249.777 67.7446 202.107 19.9931 134.726 19.9931C67.3446 19.9931 19.6751 67.7446 19.6751 125.596C19.7885 147.734 27.0684 169.239 40.4226 186.883L46.9039 195.524L33.673 228.683V228.702ZM20.1162 253.471C18.381 254.053 16.5156 254.125 14.7404 253.68C12.9653 253.235 11.3544 252.29 10.0983 250.958C8.84207 249.625 7.993 247.96 7.65132 246.16C7.30965 244.36 7.48964 242.499 8.17006 240.798L25.1017 198.423C9.24733 177.452 0.614702 151.897 0.5 125.596C0.5 61.9653 51.9277 0.792603 134.726 0.792603C217.524 0.792603 268.952 61.9653 268.952 125.596C268.952 189.226 216.987 250.399 134.726 250.399C110.853 250.399 89.5302 245.253 71.2946 236.382L20.097 253.471H20.1162Z" fill="#763393" fill-opacity="0.2"/>
                                    </svg>
                                </div>
                                <div class="arrow-clip1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="242" height="309" viewBox="0 0 242 309" fill="none">
                                        <path d="M287.605 31.4762L240.12 47.3245L231.567 43.1637C211.773 33.5566 189.152 28.4375 164.451 28.4375C82.7537 28.4375 24.238 86.8283 24.238 157C24.238 227.429 82.3331 285.563 164.451 285.563C246.57 285.563 304.665 227.429 304.665 157C304.527 130.048 295.655 103.868 279.38 82.387L271.481 71.8682L287.605 31.4996L287.605 31.4762ZM304.127 1.32248C306.242 0.613771 308.515 0.52524 310.679 1.06735C312.842 1.60947 314.805 2.75958 316.336 4.38187C317.867 6.00412 318.902 8.03076 319.318 10.2224C319.735 12.414 319.515 14.679 318.686 16.75L298.051 68.3386C317.373 93.8688 327.894 124.98 328.034 157C328.034 234.465 265.358 308.938 164.451 308.938C63.5445 308.938 0.869073 234.465 0.869066 157C0.86906 79.5353 64.1988 5.06249 164.451 5.06248C193.546 5.06248 219.532 11.327 241.756 22.1262L304.151 1.32248L304.127 1.32248Z" fill="#763393" fill-opacity="0.2"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="image" [ngClass]="{'hidden': !showLanguages}">
                                <picture>
                                    <source srcset="../../../assets/images/homepage/spanish/consistency-check.webp" type="image/webp">
                                    <img src="../../../assets/images/homepage/spanish/consistency-check.png" width="763" height="460" loading="lazy" decoding="async" alt="consistency-checker">
                                </picture>
                                <!-- <img src="../../../assets/images/homepage/consistency-check.svg" class="img-fluid" loading="lazy" decoding="async" alt="consistency checker" /> -->
                                <div class="arrow-clip">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="269" height="254" viewBox="0 0 269 254" fill="none">
                                        <path d="M33.673 228.702L72.6369 215.684L79.655 219.102C95.8963 226.993 114.458 231.198 134.726 231.198C201.762 231.198 249.777 183.235 249.777 125.596C249.777 67.7446 202.107 19.9931 134.726 19.9931C67.3446 19.9931 19.6751 67.7446 19.6751 125.596C19.7885 147.734 27.0684 169.239 40.4226 186.883L46.9039 195.524L33.673 228.683V228.702ZM20.1162 253.471C18.381 254.053 16.5156 254.125 14.7404 253.68C12.9653 253.235 11.3544 252.29 10.0983 250.958C8.84207 249.625 7.993 247.96 7.65132 246.16C7.30965 244.36 7.48964 242.499 8.17006 240.798L25.1017 198.423C9.24733 177.452 0.614702 151.897 0.5 125.596C0.5 61.9653 51.9277 0.792603 134.726 0.792603C217.524 0.792603 268.952 61.9653 268.952 125.596C268.952 189.226 216.987 250.399 134.726 250.399C110.853 250.399 89.5302 245.253 71.2946 236.382L20.097 253.471H20.1162Z" fill="#763393" fill-opacity="0.2"/>
                                    </svg>
                                </div>
                                <div class="arrow-clip1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="242" height="309" viewBox="0 0 242 309" fill="none">
                                        <path d="M287.605 31.4762L240.12 47.3245L231.567 43.1637C211.773 33.5566 189.152 28.4375 164.451 28.4375C82.7537 28.4375 24.238 86.8283 24.238 157C24.238 227.429 82.3331 285.563 164.451 285.563C246.57 285.563 304.665 227.429 304.665 157C304.527 130.048 295.655 103.868 279.38 82.387L271.481 71.8682L287.605 31.4996L287.605 31.4762ZM304.127 1.32248C306.242 0.613771 308.515 0.52524 310.679 1.06735C312.842 1.60947 314.805 2.75958 316.336 4.38187C317.867 6.00412 318.902 8.03076 319.318 10.2224C319.735 12.414 319.515 14.679 318.686 16.75L298.051 68.3386C317.373 93.8688 327.894 124.98 328.034 157C328.034 234.465 265.358 308.938 164.451 308.938C63.5445 308.938 0.869073 234.465 0.869066 157C0.86906 79.5353 64.1988 5.06249 164.451 5.06248C193.546 5.06248 219.532 11.327 241.756 22.1262L304.151 1.32248L304.127 1.32248Z" fill="#763393" fill-opacity="0.2"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd trinka-platform">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center ">
                    <h2>Make Your Writing Unique</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
                <div class="platform-card">
                    <div class="card-top" id="paraphraser">
                        <span class="capella"><img src="../../../assets/images/homepage/capella-shape.svg" width="619" height="583"  loading="lazy" decoding="async" alt="capella" /></span>
                        <h3>
                            <img src="../../../assets/images/paraphraser-icon.svg" width="24" height="24" loading="lazy" decoding="async" alt="paraphraser-icon">
                            Paraphraser
                        </h3>
                        <div class="list-wrapper-image">
                            <picture>
                                <source srcset="../../../assets/images/homepage/pharaphraser.webp" type="image/webp">
                                <img src="../../../assets/images/homepage/pharaphraser.png" width="322" height="263" loading="lazy" decoding="async" alt="paraphraser">
                            </picture>
                            <!-- <img src="../../../assets/images/homepage/pharaphraser.svg" loading="lazy" decoding="async" class="img-fluid" alt="paraphraser icon" style="transform: translate(30px, 0px);" /> -->
                        </div>
                    </div>
                    <div class="card-bottom">
                        <p>Experience enhanced clarity in your writing using Trinka's Paraphrasing tool, designed to provide coherent paraphrases while preserving the original meaning. Available for English and Spanish</p>
                        <a [routerLink]="['/features/paraphraser']">Explore 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8.625 5.25L15.375 12L8.625 18.75" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="platform-card" id="plagiarism-check">
                    <div class="card-top">
                        <span class="capella"><img src="../../../assets/images/homepage/capella-shape1.svg" width="619" height="583" loading="lazy" decoding="async" alt="capella" /></span>
                        <h3>
                            <img src="../../../assets/images/plag-check-icon.svg" width="24" height="24" loading="lazy" decoding="async" alt="Plagiarism Checker">
                            Plagiarism Checker
                        </h3>
                        <div class="list-wrapper-image">
                            <picture>
                                <source srcset="../../../assets/images/homepage/plag-check.webp" type="image/webp">
                                <img src="../../../assets/images/homepage/plag-check.png" class="plag-check-img" width="332" height="275" loading="lazy" decoding="async" alt="Plagiarism Checker">
                            </picture>
                            <!-- <img src="../../../assets/images/homepage/plag-check.svg" loading="lazy" decoding="async" class="img-fluid" alt="Plagiarism Checker" style="transform: translate(30px, 0px);" /> -->
                        </div>
                    </div>
                    <div class="card-bottom">
                        <p>Get access to market leading Plagiarism checker at a competitive price. Scan your document in the largest database (includes paid content from all top publishers) in the world. </p>
                        <a [routerLink]="['/features/plagiarism-check']">Explore 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8.625 5.25L15.375 12L8.625 18.75" stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>   
    </div>       
</section>

<section class="list-wrapper-style-2 section-pd bg-light-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center ">
                    <h2>Writing is Now Easier Than Ever</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="writing_tool_content">
            <div class="row no-margin align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 pr-80">
                        <h3>Academic and Technical Writing</h3>
                        <p>Trinka finds difficult errors unique to academic writing that other grammar checker tools don’t. From advanced English grammar errors to scientific tone and style, Trinka checks it all!</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-image bg-features">
                        <picture>
                            <source srcset="../../../assets/images/homepage/technical-writing.webp" type="image/webp">
                            <img src="../../../assets/images/homepage/technical-writing.png" width="371" height="305" loading="lazy" decoding="async" alt="academic grammar checker">
                        </picture>
                        <!-- <img src="../../../assets/images/homepage/technical-writing.png" loading="lazy" decoding="async" class="img-fluid" alt="technical" /> -->
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 34 34" fill="none">
                                <path d="M32.7327 16.5311C33.1681 16.6922 33.1681 17.3078 32.7327 17.4689L21.8072 21.5118C21.6703 21.5624 21.5624 21.6703 21.5118 21.8072L17.4689 32.7327C17.3078 33.1681 16.6922 33.1681 16.5311 32.7327L12.4882 21.8072C12.4376 21.6703 12.3297 21.5624 12.1928 21.5118L1.26725 17.4689C0.831943 17.3078 0.831943 16.6922 1.26725 16.5311L12.1928 12.4882C12.3297 12.4376 12.4376 12.3297 12.4882 12.1928L16.5311 1.26725C16.6922 0.831944 17.3078 0.831943 17.4689 1.26725L21.5118 12.1928C21.5624 12.3297 21.6703 12.4376 21.8072 12.4882L32.7327 16.5311Z" fill="white"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="writing_tool_content mt-40">
            <div class="row no-margin flex-row-reverse align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2">
                        <h3>Formal Writing</h3>
                        <p>Trinka streamlines your writing by identifying grammatical errors, analyzing sentence structure, suggesting optimal word choices, and ensuring adherence to both US and UK English styles.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-image bg-features">
                        <picture>
                            <source srcset="../../../assets/images/homepage/general-writing.webp" type="image/webp">
                            <img src="../../../assets/images/homepage/general-writing.png" width="371" height="287" loading="lazy" decoding="async" alt="grammar checker for general writing">
                        </picture>
                        <!-- <img src="../../../assets/images/homepage/general-writing.svg" loading="lazy" decoding="async" class="img-fluid" alt="general-writing" /> -->
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 34 34" fill="none">
                                <path d="M32.7327 16.5311C33.1681 16.6922 33.1681 17.3078 32.7327 17.4689L21.8072 21.5118C21.6703 21.5624 21.5624 21.6703 21.5118 21.8072L17.4689 32.7327C17.3078 33.1681 16.6922 33.1681 16.5311 32.7327L12.4882 21.8072C12.4376 21.6703 12.3297 21.5624 12.1928 21.5118L1.26725 17.4689C0.831943 17.3078 0.831943 16.6922 1.26725 16.5311L12.1928 12.4882C12.3297 12.4376 12.4376 12.3297 12.4882 12.1928L16.5311 1.26725C16.6922 0.831944 17.3078 0.831943 17.4689 1.26725L21.5118 12.1928C21.5624 12.3297 21.6703 12.4376 21.8072 12.4882L32.7327 16.5311Z" fill="white"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd trinka-key-features">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title mb-0 text-center ">
                    <h2>Key Features of Trinka</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="gt-features">
                    <a *ngFor="let feature of keyFeatures" [routerLink]="feature.route" class="gt-wrapper c-card">
                        <img [src]="feature.icon" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" [alt]="feature.alt" />
                        <h2 [innerHTML]="feature.title"></h2>
                    </a>
                    <!-- <a [routerLink]="['/features/latex-grammar-checker']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/latex.png" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="latex Proofreader" />
                        <h2>LaTeX<br/>Proofreader</h2>
                    </a>
                    <a [routerLink]="['/features/technical-checks']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/publications.png" decoding="async" loading="lazy" class="img-fluid" width="70" height="70" alt="Manuscript Checker" />
                        <h2>Technical Checks</h2>
                    </a>
                    <a [routerLink]="['/features/inclusive-language-check']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/inclusive.png" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="bias free writing" />
                        <h2>Inclusive Language Checks</h2>
                    </a>
                    <a  [routerLink]="['/features/journal-finder']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/journalFinder.png" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="find right journal" />
                        <h2>Journal <br/>Finder</h2>
                    </a>
                    <a [routerLink]="['/features/proofread-file']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/proofreading.png" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="proofreading file" />
                        <h2>Proofread <br/>File</h2>
                    </a>
                    <a [routerLink]="['/features/citation-checker']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/citation.png" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="check for correct references" />
                        <h2>Citation <br/>Checker</h2>
                    </a>
                    <a [routerLink]="['/features/personal-dictionary']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/personal-dictionary.svg" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="dictionary" />
                        <h2>Personal <br/> Dictionary</h2>
                    </a>
                    <a [routerLink]="['/features/legal-writing']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/legal-writing.png"  loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="writing tool for lawyers" />
                        <h2>Legal <br/>Writing</h2>
                    </a>
                    <a [routerLink]="['/features/consistency-check']" class="gt-wrapper c-card">
                        <img src="../../../assets/images/homepage/icons/consistency.png" loading="lazy" decoding="async" class="img-fluid" width="70" height="70" alt="consistency checker" />
                        <h2>Consistency <br/>Check</h2>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd-sm trinka-banner gcList">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-white text-center ">
                    <h2 class="text-white">Available Wherever You Need It</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
            <div class="col-lg-10 col-md-12">
                <ul>
                    <li *ngFor="let icon of icons">
                        <img [src]="icon.src" loading="lazy" decoding="async" [width]="icon.width" [height]="icon.height" [alt]="icon.name" />
                    </li>
                    <!-- <li>
                        <img src="../../../../assets/images/list-icons/google-gmail.svg" loading="lazy" decoding="async" width="27" height="21" alt="gmail" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/chrome.svg" loading="lazy" decoding="async" width="28" height="27" alt="chrome" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/linkedin.svg" loading="lazy" decoding="async" width="27" height="27" alt="linkedin" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/bing.svg" loading="lazy" decoding="async" width="18" height="28" alt="bing" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/apple.svg" loading="lazy" decoding="async" width="32" height="31" alt="apple" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/facebook.svg" loading="lazy" decoding="async" width="29" height="29" alt="facebook" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/office.svg" loading="lazy" decoding="async" width="21" height="25" alt="office" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/googledocs.svg" loading="lazy" decoding="async" width="27" height="27" alt="googledocs" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/medium.svg" loading="lazy" decoding="async" width="39" height="6"  alt="medium" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/powerpoint.svg" loading="lazy" decoding="async" width="32" height="32"  alt="powerpoint" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/asana.svg" loading="lazy" decoding="async" width="39" height="8"  alt="asana" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/word.svg" loading="lazy" decoding="async" width="29" height="30"  alt="word" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/notion.svg" loading="lazy" decoding="async" width="39" height="14"  alt="notion" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/firefox.svg" loading="lazy" decoding="async" width="27" height="28"  alt="firefox" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/twitter.svg" loading="lazy" decoding="async" width="27" height="28"  alt="twitter" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/outlook.svg" loading="lazy" decoding="async" width="30" height="30"  alt="outlook" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/quora.svg" loading="lazy" decoding="async" width="33" height="9"  alt="quora" />
                    </li>
                    <li>
                        <img src="../../../../assets/images/list-icons/bi_substack.svg" loading="lazy" decoding="async" width="20" height="20" alt="bi_substack" />
                    </li> -->
                </ul>
                <p>and many more..</p>
            </div>
        </div>
    </div>
</section>

<app-safe-secure></app-safe-secure>

<section class="trinka-business section-pd-sm">
    <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" width="745" height="472" viewBox="0 0 745 472" fill="none">
            <path d="M310.653 0H745V472H0L310.653 0Z" fill="#EEEFF3"/>
        </svg>
    </div>
    <div class="trinka_container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="trinka-title mb-0 text-white">
                    <h2 class="text-white">Trinka for Enterprise</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p class="text-white">Easily integrate Trinka for Enterprise into your workflow. Customize Trinka to match the specific requirements of your company. Multiple partnership & pricing models available. </p>
                    <div class="btn-group mt-40">
                        <a  [routerLink]="['/enterprise']" class="white-btn" aria-label="Explore More">Explore More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="card">
                    <div class="media">
                        <div class="media-left">
                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M35.2122 9.89046C35.2122 9.44863 34.8542 9.09106 34.4123 9.09106C33.9757 9.09106 33.4887 9.09106 33.311 9.09106C28.3942 9.09106 24.0752 7.27667 20.5845 3.96299C20.2584 3.65343 19.7426 3.65343 19.4165 3.963C15.9259 7.27656 11.6073 9.09106 6.69068 9.09106C6.51291 9.09106 6.02576 9.09106 5.58908 9.09106C5.14727 9.09106 4.78922 9.44861 4.7892 9.89042C4.78915 11.1266 4.78906 13.5248 4.78906 15.1113C4.78906 25.9371 11.2528 35.0337 20.0006 37.6128C28.7485 35.0337 35.2122 25.9371 35.2122 15.1113C35.2122 13.5248 35.2122 11.1266 35.2122 9.89046Z" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <g clip-path="url(#clip0_2647_2203)">
                                <path d="M14.6978 29.25H25.3039C25.7432 29.25 26.0993 28.8938 26.0993 28.4545V19.4394C26.0993 19.0001 25.7432 18.6439 25.3039 18.6439H24.5084V16.2576C24.5084 13.7721 22.4863 11.75 20.0008 11.75C17.5154 11.75 15.4933 13.7721 15.4933 16.2576V18.6439H14.6978C14.2585 18.6439 13.9023 19.0001 13.9023 19.4394V28.4545C13.9023 28.8938 14.2585 29.25 14.6978 29.25ZM20.7963 24.2114V25.2727C20.7963 25.712 20.4401 26.0682 20.0008 26.0682C19.5615 26.0682 19.2054 25.712 19.2054 25.2727V24.2114C18.8836 23.9693 18.6751 23.5843 18.6751 23.1515C18.6751 22.4205 19.2698 21.8258 20.0008 21.8258C20.7319 21.8258 21.3266 22.4205 21.3266 23.1515C21.3266 23.5843 21.1181 23.9693 20.7963 24.2114ZM17.0842 16.2576C17.0842 14.6493 18.3926 13.3409 20.0008 13.3409C21.6091 13.3409 22.9175 14.6493 22.9175 16.2576V18.6439H17.0842V16.2576Z" fill="#7A28A0"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2647_2203">
                                <rect width="17.5" height="17.5" fill="white" transform="translate(11.25 11.75)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="media-body">
                            <h3>Data Security</h3>
                            <p>Meet your compliance and data security needs with Trinka Sensitive Data Plan.</p>
                        </div>
                    </div>
                    <div class="media">
                        <div class="media-left">
                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2647_2208)">
                                <path d="M36.166 11.8334H26.166" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.50065 11.8334H2.83398" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.1673 18.4167C19.8032 18.4167 22.7507 15.4692 22.7507 11.8333C22.7507 8.19746 19.8032 5.25 16.1673 5.25C12.5314 5.25 9.58398 8.19746 9.58398 11.8333C9.58398 15.4692 12.5314 18.4167 16.1673 18.4167Z" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M36.1667 30.1666H29.5" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.834 30.1666H2.83398" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.8333 36.75C26.4692 36.75 29.4167 33.8026 29.4167 30.1667C29.4167 26.5308 26.4692 23.5834 22.8333 23.5834C19.1975 23.5834 16.25 26.5308 16.25 30.1667C16.25 33.8026 19.1975 36.75 22.8333 36.75Z" stroke="#7A28A0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2647_2208">
                                <rect y="0.5" width="40" height="40" rx="19" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>    
                        </div>
                        <div class="media-body">
                            <h3>Custom Style Guides</h3>
                            <p>Get customized style guide as per your organization style rules.</p>
                        </div>
                    </div>
                    <div class="media">
                        <div class="media-left">   
                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="7.75" y="8.25" width="24.5" height="24.5" rx="3.25" fill="white" stroke="#7A28A0" stroke-width="1.5"/>
                                <path d="M14.7531 22.0563H17.1109L17.6266 23.3969H18.9203L16.5578 17.5H15.2969L13 23.3969H14.2656L14.7531 22.0563ZM15.9156 18.8734L16.7266 21.0625H15.1187L15.9156 18.8734ZM20.7438 21.175H21.5219C22.0609 21.175 22.4734 21.1469 22.7594 21.0906C22.9703 21.0437 23.1766 20.95 23.3781 20.8094C23.5797 20.6687 23.7484 20.4766 23.8797 20.2281C24.0109 19.9797 24.0766 19.675 24.0766 19.3141C24.0766 18.8453 23.9641 18.4609 23.7344 18.1656C23.5047 17.8703 23.2234 17.6781 22.8859 17.5891C22.6656 17.5281 22.1922 17.5 21.4703 17.5H19.5531V23.3969H20.7438V21.175ZM20.7438 18.4984H21.3203C21.7516 18.4984 22.0328 18.5125 22.1781 18.5406C22.3703 18.5734 22.5297 18.6625 22.6562 18.8031C22.7828 18.9437 22.8438 19.1219 22.8438 19.3328C22.8438 19.5062 22.7969 19.6609 22.7078 19.7922C22.6187 19.9234 22.4922 20.0219 22.3375 20.0828C22.1781 20.1437 21.8641 20.1766 21.3953 20.1766H20.7438V18.4984ZM25.0141 17.5H26.2047V23.3969H25.0141V17.5Z" fill="#7A28A0"/>
                                <path d="M25.5109 29.5047H11.6453C11.2563 29.5047 10.9375 29.1859 10.9375 28.7969V14.9688C10.9375 14.7109 10.7266 14.5 10.4688 14.5C10.2109 14.5 10 14.7109 10 14.9688V28.7969C10 29.7063 10.7359 30.4422 11.6453 30.4422H25.5109C25.7688 30.4422 25.9797 30.2313 25.9797 29.9734C25.9797 29.7156 25.7734 29.5047 25.5109 29.5047Z" fill="#C084FC"/>
                                <path d="M15.5 7.5L15.5 3.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M18.5 7.5L18.5 3.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M21.5 7.5L21.5 3.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M24.5 7.5L24.5 3.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M33 15.5L37 15.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M33 18.5L37 18.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M33 21.5L37 21.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M33 24.5L37 24.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3 15.5L7 15.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3 18.5L7 18.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3 21.5L7 21.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3 24.5L7 24.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M15.5 37.5L15.5 33.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M18.5 37.5L18.5 33.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M21.5 37.5L21.5 33.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M24.5 37.5L24.5 33.5" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>   
                        </div>
                        <div class="media-body">
                            <h3>API</h3>
                            <p>Intergrate our enterprise grade API into your application.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client_rating_logo section-pd">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center ">
                    <h2>Rated Highly by Users Globally</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-9">
                <div class="client_logo_right">
                    <div class="row">
                        <div class="col-md-3 col-6">
                            <div class="client-rating sourceforge">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/SourceForge_logo.png" loading="lazy" decoding="async" alt="source forge"  />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                </ul>
                                <p>5 out of 5</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="client-rating trustpilot">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/trustpilot-1.png" loading="lazy" decoding="async" alt="trustpilot" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star"></i></li>
                                </ul>
                                <p>4 out of 5</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="client-rating product-hunt">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/product-hunt.png" loading="lazy" decoding="async" alt="product hunt" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                </ul>
                                <p>5 out of 5</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="client-rating g2">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/G2_Crowd.png" loading="lazy" decoding="async" alt="g2" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                </ul>
                                <p>5 out of 5</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="client-rating capterra">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/capterra-inc.png" loading="lazy" decoding="async" alt="capterra" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                </ul>
                                <p>5 out of 5</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="client-rating chrome-tool">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/chrome.png" loading="lazy" decoding="async" alt="chrome" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star-half-o active"></i></li>
                                </ul>
                                <p>4.3 out of 5</p>
                            </div>
                        </div>
                        
                        
                        <div class="col-md-3 col-6">
                            <div class="client-rating trustradius">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/trust-radius.png" loading="lazy" decoding="async" alt="trust radius" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star-half-o active"></i></li>
                                </ul>
                                <p>8.4 out of 10</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="client-rating software-suggest">
                                <div class="logo">
                                    <img src="../../../assets/images/global-logos/softwaresuggest-social.png" loading="lazy" decoding="async" alt="software suggest" />
                                </div>
                                <ul>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                    <li><i class="fa fa-star active"></i></li>
                                </ul>
                                <p>5 out of 5</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="testimonial-section text-center section-pd">
    <app-testimonial></app-testimonial>
</div>

<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Mumbai, India",
        "postalCode": "400062",
        "streetAddress": "Office No 801, 8th Floor, Techniplex – II Off SV Road, Goregaon - West"
      },
      "email": "support@trinka.ai",
      "image": "https://www.trinka.ai/assets/images/trinka-logo.png",
      "member": [
        {
          "@type": "Organization"
        },
        {
          "@type": "Organization"
        }
      ],
      "name": "Crimson AI",
      "sameAs": [
              "https://www.facebook.com/thetrinkaai/",
              "https://www.instagram.com/thetrinkaai/",
              "https://www.linkedin.com/company/thetrinkaai"
            ]
    }
    </script>