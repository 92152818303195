import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { WebsiteService } from 'src/app/_services/website.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-data-sensitive-plan',
  templateUrl: './data-sensitive-plan.component.html',
  styleUrls: ['./data-sensitive-plan.component.scss']
})
export class DataSensitivePlanComponent implements OnInit, AfterViewInit {
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  constructor(private titleService: Title,
    private toastr: ToastrService,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    @Inject(WINDOW) private window: Window,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public loadScript: LoadScriptService
  ) { 

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormWebsite': ['', Validators.compose([Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')])],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
  }
  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle("Protect Your Private Data With Trinka's Sensitive Data Plan");
    this.metaTagService.updateTag({ name: 'description', content: "Made for enterprises that need the highest level of security and full data control, Trinka's Sensitive Data Plan neither saves your data nor uses it for AI training." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Protect Your Private Data With Trinka's Sensitive Data Plan" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Made for enterprises that need the highest level of security and full data control, Trinka's Sensitive Data Plan neither saves your data nor uses it for AI training." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: "Protect Your Private Data With Trinka's Sensitive Data Plan" });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Made for enterprises that need the highest level of security and full data control, Trinka's Sensitive Data Plan neither saves your data nor uses it for AI training." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/enterprise/sensitive-data-plan' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/enterprise/sensitive-data-plan' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/enterprise/sensitive-data-plan' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/enterprise/sensitive-data-plan' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/enterprise/sensitive-data-plan' });
    //this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/enterprise/sensitive-data-plan' });
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/hubslider.js', function () { });
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
      this.country = localStorage.getItem("country")? localStorage.getItem("country"):"en";
    }

    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  ngAfterViewInit() {
    
  }

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo + "||" + this.contactForm.value.contactFormWebsite
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query,this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm)
    }
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  
}
