<section class="section-pd-sm pb-20 trinka-banner list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-white text-center mb-0">
                    <h2 class="text-white">What Makes Us Stand Out?</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <div class="flex-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path d="M21.587 2.76294H10.4137C5.56033 2.76294 2.66699 5.65627 2.66699 10.5096V21.6696C2.66699 26.5363 5.56033 29.4296 10.4137 29.4296H21.5737C26.427 29.4296 29.3203 26.5363 29.3203 21.6829V10.5096C29.3337 5.65627 26.4403 2.76294 21.587 2.76294ZM14.6003 23.4429C14.2137 23.8296 13.4803 24.2029 12.947 24.2829L9.66699 24.7496C9.54699 24.7629 9.42699 24.7763 9.30699 24.7763C8.76033 24.7763 8.25366 24.5896 7.89366 24.2296C7.45366 23.7896 7.26699 23.1496 7.37366 22.4429L7.84033 19.1629C7.92033 18.6163 8.28033 17.8963 8.68033 17.5096L14.627 11.5629C14.7337 11.8429 14.8403 12.1229 14.987 12.4429C15.1203 12.7229 15.267 13.0163 15.427 13.2829C15.5603 13.5096 15.707 13.7229 15.827 13.8829C15.9737 14.1096 16.147 14.3229 16.2537 14.4429C16.3203 14.5363 16.3737 14.6029 16.4003 14.6296C16.7337 15.0296 17.1203 15.4029 17.4537 15.6829C17.547 15.7763 17.6003 15.8296 17.627 15.8429C17.827 16.0029 18.027 16.1629 18.2003 16.2829C18.4137 16.4429 18.627 16.5896 18.8537 16.7096C19.1203 16.8696 19.4137 17.0163 19.707 17.1629C20.0137 17.2963 20.2937 17.4163 20.5737 17.5096L14.6003 23.4429ZM23.1603 14.8829L21.9337 16.1229C21.8537 16.2029 21.747 16.2429 21.6403 16.2429C21.6003 16.2429 21.547 16.2429 21.5203 16.2296C18.8137 15.4563 16.6537 13.2963 15.8803 10.5896C15.8403 10.4429 15.8803 10.2829 15.987 10.1896L17.227 8.94961C19.2537 6.92294 21.187 6.96294 23.1737 8.94961C24.187 9.96294 24.6803 10.9363 24.6803 11.9496C24.667 12.9096 24.1737 13.8696 23.1603 14.8829Z" fill="white"/>
                          </svg>
                        <h3 class="text-white tx-18 mb-0">20+ Years of Expert
                            Editorial Intelligence</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="list-wrapper-content-2 list-style-cards">
                    <div class="middle-card middle-card-white">
                        <div class="flex-title">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                <path d="M18.6403 10.4294H13.347C11.6803 10.4294 10.3203 11.7761 10.3203 13.4561V18.7494C10.3203 20.4161 11.667 21.7761 13.347 21.7761H18.6403C20.307 21.7761 21.667 20.4294 21.667 18.7494V13.4561C21.667 11.7761 20.3203 10.4294 18.6403 10.4294ZM18.0003 17.4028L16.8136 19.4694C16.6403 19.7761 16.3203 19.9361 16.0003 19.9361C15.8403 19.9361 15.667 19.8961 15.5336 19.8161C15.0803 19.5628 14.9203 18.9894 15.187 18.5361L16.0403 17.0561H15.2936C14.6936 17.0561 14.2003 16.7894 13.9336 16.3361C13.667 15.8828 13.6936 15.3228 14.0003 14.8028L15.187 12.7361C15.4536 12.2828 16.027 12.1361 16.467 12.3894C16.9203 12.6428 17.0803 13.2161 16.8136 13.6694L15.9603 15.1494H16.707C17.307 15.1494 17.8003 15.4161 18.067 15.8694C18.3336 16.3228 18.307 16.8828 18.0003 17.4028Z" fill="white"/>
                                <path d="M28.3337 17.0963C28.8937 17.0963 29.3337 16.6429 29.3337 16.0963C29.3337 15.5363 28.8937 15.0963 28.3337 15.0963H26.667V12.1629H28.3337C28.8937 12.1629 29.3337 11.7229 29.3337 11.1629C29.3337 10.6163 28.8937 10.1629 28.3337 10.1629H26.3603C25.7203 8.04294 24.0537 6.37627 21.9337 5.73627V3.76294C21.9337 3.21627 21.4803 2.76294 20.9337 2.76294C20.387 2.76294 19.9337 3.21627 19.9337 3.76294V5.42961H17.0003V3.76294C17.0003 3.21627 16.547 2.76294 16.0003 2.76294C15.4537 2.76294 15.0003 3.21627 15.0003 3.76294V5.42961H12.0803V3.76294C12.0803 3.21627 11.627 2.76294 11.0803 2.76294C10.5203 2.76294 10.0803 3.21627 10.0803 3.76294V5.73627C7.94699 6.37627 6.28033 8.04294 5.64033 10.1629H3.66699C3.12033 10.1629 2.66699 10.6163 2.66699 11.1629C2.66699 11.7229 3.12033 12.1629 3.66699 12.1629H5.33366V15.0963H3.66699C3.12033 15.0963 2.66699 15.5363 2.66699 16.0963C2.66699 16.6429 3.12033 17.0963 3.66699 17.0963H5.33366V20.0296H3.66699C3.12033 20.0296 2.66699 20.4696 2.66699 21.0296C2.66699 21.5763 3.12033 22.0296 3.66699 22.0296H5.64033C6.26699 24.1496 7.94699 25.8163 10.0803 26.4563V28.4296C10.0803 28.9763 10.5203 29.4296 11.0803 29.4296C11.627 29.4296 12.0803 28.9763 12.0803 28.4296V26.7629H15.0137V28.4296C15.0137 28.9763 15.4537 29.4296 16.0137 29.4296C16.5603 29.4296 17.0137 28.9763 17.0137 28.4296V26.7629H19.9337V28.4296C19.9337 28.9763 20.387 29.4296 20.9337 29.4296C21.4803 29.4296 21.9337 28.9763 21.9337 28.4296V26.4563C24.0537 25.8163 25.7203 24.1496 26.3603 22.0296H28.3337C28.8937 22.0296 29.3337 21.5763 29.3337 21.0296C29.3337 20.4696 28.8937 20.0296 28.3337 20.0296H26.667V17.0963H28.3337ZM23.0137 19.1096C23.0137 21.3096 21.2137 23.1096 19.0137 23.1096H12.987C10.787 23.1096 8.98699 21.3096 8.98699 19.1096V13.0829C8.98699 10.8829 10.787 9.08294 12.987 9.08294H19.0137C21.2137 9.08294 23.0137 10.8829 23.0137 13.0829V19.1096Z" fill="white"/>
                            </svg>
                            <h3 class="text-white tx-18 mb-0">Proprietary Patented AI 
                                Model</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="list-wrapper-content-2 list-style-cards ">
                    <div class="flex-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path d="M21.587 2.76294H10.4137C5.56033 2.76294 2.66699 5.65627 2.66699 10.5096V21.6696C2.66699 26.5363 5.56033 29.4296 10.4137 29.4296H21.5737C26.427 29.4296 29.3203 26.5363 29.3203 21.6829V10.5096C29.3337 5.65627 26.4403 2.76294 21.587 2.76294ZM15.4803 15.8829C15.4803 19.9896 14.587 20.9096 12.2003 22.3229C12.0403 22.4163 11.867 22.4563 11.6937 22.4563C11.347 22.4563 11.0137 22.2829 10.827 21.9629C10.547 21.4829 10.707 20.8696 11.1737 20.5896C12.787 19.6296 13.347 19.2829 13.4537 16.8696H10.9203C9.46699 16.8696 8.33366 15.7363 8.33366 14.2829V12.3096C8.33366 10.8563 9.46699 9.72294 10.9203 9.72294H12.907C14.3337 9.72294 15.4937 10.8829 15.4937 12.3096V15.8829H15.4803ZM23.667 15.8829C23.667 19.9896 22.7737 20.9096 20.387 22.3229C20.227 22.4163 20.0537 22.4563 19.8803 22.4563C19.5337 22.4563 19.2003 22.2829 19.0137 21.9629C18.7337 21.4829 18.8937 20.8696 19.3603 20.5896C20.9737 19.6296 21.5337 19.2829 21.6403 16.8696H19.0937C17.6403 16.8696 16.507 15.7363 16.507 14.2829V12.3096C16.507 10.8563 17.6403 9.72294 19.0937 9.72294H21.0803C22.507 9.72294 23.667 10.8829 23.667 12.3096V15.8829Z" fill="white"/>
                        </svg>
                        <h3 class="text-white tx-18 mb-0">Highest Security for Sensitive Data</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>