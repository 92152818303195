<div class="use_trinka_other_app section-pd-sm">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white">Get Trinka Where You Need It</h3>
                </div>
                <div class="use_trinka_content">
                    <div class="others_app_name">
                        <ul>
                            <li>
                                <a [routerLink]="['/trinkacloud']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="/assets/images/Other_apps_TrinkaCloud.svg" alt="Free grammar checker tool">
                                        <h3>Cloud</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/wordplugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="/assets/images/wordAdd-in1.svg" alt="word extension">
                                        <h3>MS Word</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="/assets/images/NewChromeIcon.svg" alt="chrome extension">
                                        <h3>Chrome</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="/assets/images/NewFirefoxIcon.svg" alt="firefox extension">
                                        <h3>Firefox</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="/assets/images/EdgeWhite_BGAndOutline.svg" alt="edge extension">
                                        <h3>Edge</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/browser-plugin']">
                                    <div class="app_name_content">
                                        <span class="safariComingSoonTag">Coming Soon</span>
                                        <div class="app_name_content_overlay"></div>
                                        <img src="/assets/images/safari_logo.png" alt="safari extension">
                                        <h3>Safari</h3>
                                    </div>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>