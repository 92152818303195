<div class="cloud_top_banners">
    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1 class="trinka-sub-title">Institution Plan</h1>
                            <h2>Comprehensive Suite of AI Writing Assistance for Your University</h2>
                            <p>Provide AI writing assistance to everyone at your university with a usage-based pricing plan.</p>
                            <div class="btn-group">
                                <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn" type="button">Request Free Trial</button>
                                <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/academic.png" alt="Trinka enterprise" class="img-fluid md50">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<section class="section-pd section-purple">
    <div class="trinka_container container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="trinka-title mb-0">
                    <h2>Implemented by Top Universities Worldwide</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>No more hassle of user-license management and wastages. With our usage-based Institution plan, universities can offer complete AI writing assistance to everyone at the university.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-center">
                    <img src="../../../../assets/images/Institution-Plan-world-map.png" class="img-fluid" alt="Institution-Plan-world-map" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Key Features of Institution Plan</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards flex-title bt-none">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.5 13.75C9.5 14.72 10.25 15.5 11.17 15.5H13.05C13.85 15.5 14.5 14.82 14.5 13.97C14.5 13.06 14.1 12.73 13.51 12.52L10.5 11.47C9.91 11.26 9.51001 10.94 9.51001 10.02C9.51001 9.17999 10.16 8.48999 10.96 8.48999H12.84C13.76 8.48999 14.51 9.26999 14.51 10.24" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 7.5V16.5" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17 3V7H21" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M22 2L17 7" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Usage-based <br/>Pricing</h3>
                        <p>No money wasted on unused licenses as you pay only for usage.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards flex-title">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22.3201C17.5228 22.3201 22 17.8429 22 12.3201C22 6.79722 17.5228 2.32007 12 2.32007C6.47715 2.32007 2 6.79722 2 12.3201C2 17.8429 6.47715 22.3201 12 22.3201Z" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2 12.3201H22" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 22.3201C13.933 22.3201 15.5 17.8429 15.5 12.3201C15.5 6.79722 13.933 2.32007 12 2.32007C10.067 2.32007 8.5 6.79722 8.5 12.3201C8.5 17.8429 10.067 22.3201 12 22.3201Z" stroke="#763393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Institute-wide <br/>Access</h3>
                        <p>Provide writing assistance to everyone with the university domain email ID.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                    <div class="features_cards_icon">
                        <svg fill="#763393" width="40px" height="40px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z"></path></g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Flexible <br/>Validity</h3>
                        <p>Extend your subscription easily with roll-over flexibility.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="9" r="2" stroke="#763393" stroke-width="1.5"></circle> <path d="M16 15C16 16.1046 16 17 12 17C8 17 8 16.1046 8 15C8 13.8954 9.79086 13 12 13C14.2091 13 16 13.8954 16 15Z" stroke="#763393" stroke-width="1.5"></path> <path d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 14.4963 20.1632 16.4284 19 17.9041M3.19284 14C4.05026 18.2984 7.57641 20.5129 9.89856 21.5273C10.62 21.8424 10.9807 22 12 22C13.0193 22 13.38 21.8424 14.1014 21.5273C14.6796 21.2747 15.3324 20.9478 16 20.5328" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Full Control over Usage and Access</h3>
                        <p>Blacklist or whitelist email IDs and limit usage as needed, offering you full control over usage.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="list-wrapper-style-2 section-pd pt-0">
    <div class="trinka_container container">
        <div class="row">
            <div class="col-12">
                <div class="section-usecases section-purple rounded-sm">
                    <div class="row justify-content-center">
                        <div class="col-md-12 col-lg-12">
                            <div class="trinka-title mb-0 text-center">
                                <h2>Complete Suite of AI Products</h2>
                                <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards  bt-none">
                                <h3 class="mb-2">Writing Assistant</h3>
                                <p class="mb-3">Complete AI writing assistance designed for academics to improve language quality.</p>
                                <ul>
                                    <li><a [routerLink]="['/features/grammar-checker']">Grammar Checker</a> <small class="d-block">(Cloud, MS Word and LaTeX)</small></li>
                                    <li><a [routerLink]="['/features/paraphraser']">Paraphraser</a></li>
                                    <li><a [routerLink]="['/features/consistency-check']">Consistency Check</a></li>
                                    <li><a [routerLink]="['/features/inclusive-language-check']">Inclusive Language Check</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards">
                                <h3 class="mb-2">Reading Assistant</h3>
                                <p class="mb-3">Simplify literature review process with smart AI tools to master the literature with superhuman speed.</p>
                                <ul>
                                    <li><a href="https://www.read.enago.com/copilot/" target="_blank">Literature Review Copilot</a></li>
                                    <li><a href="https://www.read.enago.com/summarizer/" target="_blank">Summarizer</a></li>
                                    <li><a href="https://www.read.enago.com/key-insights/" target="_blank">Key Insights</a></li>
                                    <li><a href="https://www.read.enago.com/related-papers/" target="_blank">Paper Discovery</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3 class="mb-2">Integrity Reports</h3>
                                <p class="mb-3">A comprehensive suite of reports to evaluate the integrity of submitted documents.</p>
                                <ul>
                                    <li><a [routerLink]="['/ai-content-detector']">AI Content Detector</a></li>
                                    <li><a [routerLink]="['/features/plagiarism-check']">Plagiarism Checker</a></li>
                                    <li>Writing Tracking <span class="beta-badges postion-relative" style="margin-left:8px; top:inherit;right: inherit;">Coming Soon</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3 class="mb-2">Citation Tools</h3>
                                <p class="mb-3">Check & improve the citation quality and validate, format, and standardize them for all major styles.</p>
                                <ul>
                                    <li><a [routerLink]="['/features/citation-checker']">Citation Checker</a></li>
                                    <li><a [routerLink]="['/features/citation-formatter']">Citation Formatter</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3 class="mb-2">Publication Tools</h3>
                                <p class="mb-3">Evaluate documents for 25+ standard checks to increase your chances of publication.</p>
                                <ul>
                                    <li><a [routerLink]="['/features/journal-finder']">Journal Finder</a></li>
                                    <li><a [routerLink]="['/features/technical-checks']">Technical Checks Report</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3 class="mb-2">GenAI Tools</h3>
                                <p class="mb-3">Assists your faculty and students to use GenAI tools responsibly with robust security and control.</p>
                                <ul>
                                    <li>/Ask</li>
                                    <li>AI Studio</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="trinka-banner section-pd-sm">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="trinka-title text-center">
                    <h3 class="text-white mb-4">Experience the Future of Academic Writing – Free Trial Available!</h3>
                    <p class="text-white w-100">Curious about how our Institution Plan can transform your university's writing capabilities? Request a free trial today give your university the edge with Trinka’s Institution Plan.</p>
                </div>
                <div class="btn-group justify-content-center">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="white-btn" type="button">Request Free Trial</button>
                </div>
            </div>
        </div>  
    </div>
</section>

<section class="bg-light">
    <app-security-section></app-security-section>
</section>

<section class="section-pd list-wrapper-style-2 tailoredSolutions">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Your Academic Writing Assistant, <br class="d-md-block d-none"/>Anytime and Anywhere</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-70">Empower your students, faculty, and researchers with writing assistance tools that integrate effortlessly into their everyday platforms, ensuring productivity and efficiency.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>
                        Cloud <br class="d-lg-block d-none" />Editor
                    </h3>
                    <p>
                        Use Trinka's full potential anywhere, with the <span>secured web-based application.</span>
                    </p>
                    <a [routerLink]="['/trinkacloud']" class="text-link d-flex align-items-center mb-0">
                        Read More
                        <svg class="mb-0" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"><path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>
                        MS Word <br class="d-lg-block d-none" />Add-in
                    </h3>
                    <p>
                        Improve academic writing with intelligent suggestions delivered <span>directly within MS Word</span> for coursework, research papers, and more.
                    </p>
                    <a [routerLink]="['/wordplugin']" class="text-link d-flex align-items-center mb-0">
                        Read More
                        <svg class="mb-0" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"><path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>
                        Desktop <br class="d-lg-block d-none" />App
                    </h3>
                    <p>
                        Provide professional grammar corrections, while writing in <span>various applications on Windows</span>.
                    </p>
                    <a [routerLink]="['/windows-desktop-app']" class="text-link d-flex align-items-center mb-0">
                        Read More
                        <svg class="mb-0" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"><path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 mh-xl">
                    <h3>
                        Browser <br class="d-lg-block d-none" />Plug-ins
                    </h3>
                    <p>
                        Real-time writing assistance on any website through
                        <span>plugins for all your favorite browsers.</span>
                    </p>
                    <a [routerLink]="['/browser-plugin']" class="text-link d-flex align-items-center mb-0">
                        Read More
                        <svg class="mb-0" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"><path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1 mh-xl">
                    <h3>API/JS Integration</h3>
                    <p>
                        Integrate into your <span>university's Learning Management System (LMS)</span> or other platforms using our powerful API and JavaScript solutions.
                    </p>
                    <a [routerLink]="['/enterprise/grammar-checker-api']" class="text-link d-flex align-items-center mb-0">
                        Read More
                        <svg class="mb-0" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"><path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section-pd section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center">
                    <h2>Personalized Pricing that Works for You</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>
                        Trinka offers flexible per-user or usage based pricing to suit your institution needs. 
                        Switch users and apply usage restrictions in real time, at no extra cost. <br />
                        We'll work with you to create a hassle-free custom contract and single payment option via PO/invoice.
                    </p>
                    <div class="btn-group justify-content-center mt-4">
                        <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Get a Quote</button>
                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-6 col-lg-6">
                <div class="trinka-title">
                    <h3 class="text-white mb-4">Trinka Simplifies Invoices with Support for Local Currency.</h3>
                    <p class="text-white">Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, EUR, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                </div>
                <div class="btn-group">
                    <a [routerLink]="['/local-invoice']" class="white-btn">Check Invoice Options</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="text-center">
                    <img src="../../../assets/images/local-inovice-element.png" loading="lazy"  decoding="async" alt="local invoice" class="img-fluid" />
                </div>
            </div>
        </div>  
    </div>
</div>

<app-enterprise-modal></app-enterprise-modal>