import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-trinka',
  templateUrl: './get-trinka.component.html',
  styleUrls: ['./get-trinka.component.scss']
})
export class GetTrinkaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
