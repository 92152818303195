import { Component, OnInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
//import * as AOS from 'aos';
import { LinkService } from '../../_services/langhfre.service';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent implements OnInit {
  
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    public loadScript: LoadScriptService
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Trinka Enterprise - Writing Assistant & Grammar Checker For Your Teams.');
    this.metaTagService.updateTag({ name: 'description', content: "Easily integrate Trinka into your enterprise workflows and give your team access to all of Trinka's powerful capabilities." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Trinka Enterprise - Writing Assistant & Grammar Checker For Your Teams." });
    this.metaTagService.updateTag({ property: 'og:description', content: "Easily integrate Trinka into your enterprise workflows and give your team access to all of Trinka's powerful capabilities." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Enterprise - Writing Assistant & Grammar Checker For Your Teams.' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Easily integrate Trinka into your enterprise workflows and give your team access to all of Trinka's powerful capabilities." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/enterprise' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/enterprise' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      $.getScript('../../../assets/js/hubslider.js', function () { });
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
      //AOS.init();
      //this.country = localStorage.getItem("country")? localStorage.getItem("country"):"en";
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka Enterprise","item":"https://www.trinka.ai/enterprise"}]}
    `;
    this._renderer2.appendChild(this._document.head, script);
    
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // console.log("Country is", this.country);
  }


}

