<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Trinka for Tech Platforms</h1>
                                <h2>Writing Assistance Within Your Application</h2>
                                <p>Trinka AI allows you to add real-time writing support right within your application with ease. Build unique solutions by offering Trinka’s precise writing suggestions in a variety of ways for your users.</p>
                                    <div class="btn-group">
                                        <button data-toggle="modal"
                                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Get a Quote</button>
                                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                                    </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/technologyPlatform.png" alt="Trinka enterprise" class="img-fluid md50">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- <div class="most_suited_for_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="most_united_heading">
                        <h2>
                            <span></span>Unique Integrations to Power Bespoke Applications</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="most_suited_point">
                        <ul>
                            <li>Content Management Systems</li>
                            <li>Online Writing Platforms</li>
                            <li>Messaging Apps/Social Platforms</li>
                            <li>CRM + Helpdesk Software</li>
                            <li>Email Clients</li>
                            <li>Project Management Software </li>
                            <li>E-learning Platforms</li>
                            <li>Learning Management Systems</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <section class="section-pd trinka-key-features">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Unique Integrations to Power Bespoke Applications</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="gt-features three-coloum">
                        <span class="hr-line"></span>
                        <span class="vr-line l25"></span>
                        <span class="vr-line l50"></span>
                        <span class="vr-line l75"></span>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Content Management Systems
                            </h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Online Writing Platforms</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Messaging Apps/Social Platforms</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>CRM + Helpdesk Software</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Email Clients</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Project Management Software</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>E-learning Platforms</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Learning Management Systems</h6>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    </section>
    <!-- <div class="brandLogo_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-2">
                    <h2>Trusted by Global Leaders</h2>
                </div>
                <div class="col-sm-10">
                    <div class="cover_wrapper">
                        <div id="client-logos-enterprise" class="owl-carousel">
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/j-j.png" alt="">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/cigna.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/novartis.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/merck.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/abbvie.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/amgen.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/biogen.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/who.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/accelera.png" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/pfizer.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> -->
    <div class="section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Unique Features to Power Unique Experiences in Your Products</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Improve your product and customer experience with unlimited access to all of Trinka’s powerful capabilities.
                            From advanced English grammar corrections to enhancements in tone and style, Trinka offers comprehensive
                            writing assistance.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-none">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/Real-timewritingsuggestions.png" alt="real time writing" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Advanced Real-time Writing Assistance Within Applications</h3>
                            <p>Trinka AI checks for complex grammar errors in real time, right within your application.
                                Besides grammar checks, Trinka improves writing for vocabulary, tone, syntax, phrasing,
                                and more.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/Qualityassessment.png" alt="quality assessment" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Custom Scoring <br class="d-lg-block d-none"/>and Personal<br class="d-lg-block d-none"/> Dictionaries</h3>
                            <p>Score your user’s writing with customizable scoring algorithms and let them personalize
                                their writing assistance with user-specific dictionaries.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/WhiteLabel.png" alt="white label" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>White Label</h3>
                            <p>With complete white labelling, Trinka will seamlessly integrate into your product. Continue
                                leveraging the power and trust of your brand using our bespoke white-labelled solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/DedicatedSupport.svg" alt="dedicated supported" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Dedicated Support </h3>
                            <p>A dedicated Success Manager is assigned to support your team to get the most out of Trinka.
                                This includes a detailed on-boarding call, help documentation, and video walk-throughs.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
                  

        </div>
    </div>
</div>

<div class="whiteListing section-pd">
    <div class="trinka_container">
        <div class="row align-items-center" style="margin-bottom: 40px;">
            <div class="col-md-8 col-sm-8">
                <div class="trinka-title mb-0">
                    <h2>IP Whitelisting</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>IP whitelisting is a security feature to help protect sensitive data and systems from unauthorized access. It is beneficial for your business as it can enhance security by controlling who can view those resources, complying with regulations, improving productivity, and saving money. IP whitelisting is achieved by limiting access only to specific IP addresses from a trusted source.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4">
                <div class="benefitImage">
                    <img src="../../../assets/images/the-benefit-whitepaper.png" width="200" alt="The benefits" class="img-fluid" />
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-8 col-sm-12">
                <div class="trinka-title mb-0">
                    <h3 class="mb-2">Benefits</h3>
                    <p>Our IP whitelisting security feature allows access to Trinka only from specified IP addresses and below are the benefits.</p>
                </div>
            </div>
            
        </div>
        <div class="row match-box">
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Enhanced security <span><img src="../../../assets/images/icons/cyber-security.png" alt="Enhanced security" /></span></h4>
                        <p>Trinka can be accessed only from trusted IP addresses, reducing the risk of unauthorized access and cyber attacks</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Controlled Access <span><img src="../../../assets/images/icons/browser.png" alt="Controlled Access" /></span></h4>
                        <p>Administrators have granular control of who can access Trinka by quickly blocking or unblocking access to specific IP addresses</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Reduced downtime <span><img src="../../../assets/images/icons/clock.png" alt="Reduced downtime" /></span></h4>
                        <p>IP whitelisting controls and reduces the risk of denial-of-service (DoS) attacks and stoppages that can affect genuine users</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Better compliance <span><img src="../../../assets/images/icons/growth.png" alt="Better compliance" /></span></h4>
                        <p>Allows organizations to meet strict industry regulatory compliances, especially where IP whitelisting is a mandate.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row">
            <div class="col-12">
                <div class="section-usecases section-purple rounded-sm">
                    <div class="row justify-content-center">
                        <div class="col-md-12 col-lg-12">
                            <div class="trinka-title mb-0 text-center">
                                <h2>Flexible Integrations for Every Use Case</h2>
                                <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                                <p>Use Trinka’s various features to build features that drive in growth</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards  bt-none">
                                <h3>Real-time<br class="d-lg-block d-none"/> Assistance</h3>
                                <p>Let your users get writing feedback instantly within the comfort of your application.</p>
                                <ul>
                                    <li>3000+ grammar checks </li>
                                    <li>Detailed explanations</li>
                                    <li>Custom corrections </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards ">
                                <h3>Writing Quality Assessment</h3>
                                <p>Trinka AI provides customizable quality scores and error analyses that can power bespoke
                                    learning experiences within learning management systems (LMS), e-Learning apps, and more.
                                </p>
                                <ul>
                                    <li>Writing quality scores</li>
                                    <li>Categorized writing error report</li>
                                    <li>Interactive corrections with explanations</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3>Off-the-shelf Personalization </h3>
                                <p>Trinka lets you offer personalized experiences with support for user dictionaries, customizable
                                    corrections and style guides, and custom dashboards.</p>
                                <ul>
                                    <li>Let users create their personal dictionaries</li>
                                    <li>Add custom style guides relevant to your brand/application</li>
                                    <li>Derive precise insights with powerful data collection and monitoring</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="advanceGrammerSpellCheck_section backgrondSecitons_outer accomplishSections sec2">
    <div class="container">
        <div class="row">
            <div class="col-xl-4">
                <div class="spellcheck_heading EnhancementsHeadingSection">
                    <h2>Flexible Integrations for Every Use Case </h2>
                    <div class="HeadingSubLine"></div>
                    <p>Use Trinka’s various features to build features that drive in growth</p>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="all_usecases">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="all_usecases_item">
                                <h4>Real-time Assistance</h4>
                                <p>Let your users get writing feedback instantly within the comfort of your application.</p>
                                <ul>
                                    <li>3000+ grammar checks </li>
                                    <li>Detailed explanations</li>
                                    <li>Custom corrections </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="all_usecases_item">
                                <h4>Writing Quality Assessment</h4>
                                <p>Trinka AI provides customizable quality scores and error analyses that can power bespoke
                                    learning experiences within learning management systems (LMS), e-Learning apps, and more.
                                </p>
                                <ul>
                                    <li>Writing quality scores</li>
                                    <li>Categorized writing error report</li>
                                    <li>Interactive corrections with explanations</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="all_usecases_item all_usecases_item_last">
                                <h4>Off-the-shelf Personalization </h4>
                                <p>Trinka lets you offer personalized experiences with support for user dictionaries, customizable
                                    corrections and style guides, and custom dashboards.</p>
                                <ul>
                                    <li>Let users create their personal dictionaries</li>
                                    <li>Add custom style guides relevant to your brand/application</li>
                                    <li>Derive precise insights with powerful data collection and monitoring</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->

<div class="cloud_key_features section-pd list-wrapper-style-2 tailoredSolutions">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Integrate with Your Proprietary Software and Workflows</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka's easy integrations let your teams enjoy all the benefits right within your existing software, making
                        effective writing productive and seamless.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>JavaScript</h3>

                    <p>
                        Enable Trinka for all your team members by
                        <span>simply inserting a small JavaScript (JS) code snippet</span> into your browser or any application.
                    </p>

                    <a [routerLink]="['/enterprise']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>API</h3>
                    <p>
                        Trinka’s powerful API brings Trinka
                        <span>right into your existing applications.</span> With a simple API architecture, it's easy to integrate.</p>

                    <a [routerLink]="['/enterprise/grammar-checker-api']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>On Premise</h3>
                    <p>Ensure effective writing and maximum confidentiality with
                        <span>Trinka hosted on your enterprise infrastructure, be it local or cloud servers.</span>
                    </p>

                    <a [routerLink]="['/enterprise/']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="section-pd section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center">
                    <h2>Personalized Pricing that Works for You</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka offers flexible per-user or usage based pricing to suit your business needs.
                        <br>Switch users and apply usage restrictions in real time, at no extra cost.
                        <br>We'll work with you to create a hassle-free custom contract and single payment option via PO/invoice.</p>
                    <div class="btn-group justify-content-center mt-4">
                        <button data-toggle="modal"
                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Get a Quote</button>
                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-6 col-lg-6">
                <div class="trinka-title">
                    <h3 class="text-white mb-4">Trinka Simplifies Invoices with Support for Local Currency.</h3>
                    <p class="text-white">Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, EUR, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                </div>
                <div class="btn-group">
                    <a [routerLink]="['/local-invoice']" class="white-btn">Check Invoice Options</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="text-center">
                    <img src="../../../assets/images/local-inovice-element.png" alt="local invoice" class="img-fluid" />
                </div>
            </div>
        </div>  
    </div>
</div>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>Safe, Secure, and Trusted</h3>
                        <p>Trinka puts data safety and privacy at the forefront. All your data is strongly encrypted and securely
                            stored - no one else has access to your data. We offer unique plans that completely eliminate
                            saving any data once you receive writing suggestions.
                        </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>About Trinka</h3>
                    <p>Trinka uses the latest Machine Learning (ML) and Natural Language Processing (NLP) technologies to
                        correct grammar errors and suggest language enhancements. Our AI is trained on millions of well-written
                        papers and articles covering 1300+ subject areas including medical, life sciences, physical sciences,
                        engineering, humanities, business, and arts to give you the most relevant suggestions.</p>
                    <p>Trinka is developed by a team of linguists, editors, data scientists, and engineers with a deep desire
                        to create a future with barrier-free communication.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/about-ai.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>


<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Enable Your Users to Write Better</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup"
                    class="white-btn" type="submit">Get a Quote</button>
                </div>
            </div>
        </div>  
    </div>
</div>

<app-enterprise-modal></app-enterprise-modal>