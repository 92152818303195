<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Journal Finder</h1>
                            <h2>Find the Right Journal to Publish Your Paper</h2>
                            <p>Journal Finder identifies the right journal by comparing the concepts in your paper against millions of publications and publication trends.</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/journal-finder.png" alt="Journal Finder" class="img-fluid md50" style="width: 70%;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="cloud_key_features section-pd list-wrapper-style-2 section-grey">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>How Trinka’s Journal Finder Helps You</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <h3>Smoother Publication</h3>
                        <p>Journal Finder uses the latest AI technologies to understand your paper’s concepts and match them with journal publications to recommend a journal.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <h3>Evidence-Based Decision Making</h3>
                        <p>Trinka recommends the right journal for you and shows similar papers from each journal  to help you understand scope match and decide confidently.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <h3>Predatory Journal Alert</h3>
                        <p>Trinka's advanced AI identifies journals with unethical practices to prevent you from unknowingly falling victim to fraudulent publishers.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src="../../../assets/images/JournalFinderThumbnail.jpg" loading="lazy" decoding="async" alt="video thumbnail" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Check out the easy walk-through video to get started on Trinka Journal Finder. </p>
                            <p>It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                            <p class="mb-0">Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2 pb-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka’s Journal Finder</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Find the right journal for your paper quickly  and see similar papers from each journal to make an informed decision</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/icons/AI-power.svg" loading="lazy" decoding="async" alt="AI-Powered Journal Recommendations">
                        <h3>AI-Powered<br class="d-md-block d-none"/> Suggestions<br class="d-lg-block d-none"/>&nbsp;</h3>
                        <p>Journal Finder uses the latest AI technology to grasp your paper's concepts and match them with journal publications to recommend the right journal.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/icons/all-subjects.svg" loading="lazy" decoding="async" alt="Works for all Subjects Areas">
                        <h3>Works for all<br class="d-lg-block d-md-none d-sm-none d-none"/> Subjects<br class="d-lg-block d-md-none d-sm-none d-none"/> Areas</h3>
                        <p>Journal Finder recommends the right journal for all scientific disciplines, thanks to its vast database of published papers.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/icons/large-database.svg" loading="lazy" decoding="async" alt="Large Database of Scientific Publications">
                        <h3>Large Database of Scientific Publications</h3>
                        <p>Journal Finder uses a large database of scientific publications gathered from sources such as OpenAlex.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/icons/publication-trend.svg" loading="lazy" decoding="async" alt="Publication Trends to Understand Citability">
                        <h3>Publication Trends to Understand Citability</h3>
                        <p>Journal Finder shows how publications of your topic have performed over time and gives you a sense of the visibility of your paper after publication.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/icons/similar.svg" loading="lazy" decoding="async" alt="Similar Articles for Perfect Scope Match">
                        <h3>Similar Articles for Perfect Scope Match<br class="d-lg-block d-none"/>&nbsp;</h3>
                        <p>Journal Finder shows you similar articles from each suggested journal so you can be sure your paper matches the journal’s scope and avoid desk rejection.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/icons/search-intutive.svg" loading="lazy" decoding="async" alt="Intuitive Filters for Refined Search">
                        <h3>Intuitive Filters for Refined Search<br class="d-lg-block d-none"/>&nbsp;</h3>
                        <p>Journal Finder’s smart filters enable you quickly identify the perfect journal by subject, open access, impact factor, and more. You can set custom filters too!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-security-section></app-security-section>

    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            How is Trinka’s Journal Finder different from others?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Trinka's Journal Finder uses advanced AI concept-matching technologies to help you locate journals across different disciplines and publications to find the most appropriate journal for your manuscript.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">
                                            Is Journal Finder limited to open source journals? </a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>No. Trinka's database is not limited to open source journals. The database contains papers drawn from sources such as Crossref and PubMed. In case you would like to look for open source journals only, you can filter the results.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            Can I enter my choice of publisher or journal, other than the suggestions Trinka displays?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes, you can do that.  Journal Finder will check your paper to determine whether it is suitable for publishing in your journal of choice and will also suggest suitable alternatives.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="icon">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" alt="icon">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="icon">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>


                    <iframe  class="youtubeVideoForTryDemo" id="iframe"  width="100%" height="450" src="https://www.youtube.com/embed/fuWNwHYFPFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>